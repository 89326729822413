import Vue from 'vue';

export default {
  async getAll(userId) {
    if (!userId) throw Error('No userId passed to teamService.getAll');
    try {
      const response = await Vue.axios.get(`api/v1/users/${userId}/teams`);
      if (response.data === []) {
        throw Error('API error at teamService.getAll: returned empty array');
      }
      return response.data;
    } catch (err) {
      throw Error(`API error at teamService.getAll: ${err.message}`);
    }
  },
};
