/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */

import { normalize, schema } from 'normalizr';
import segmentService from '../../services/segment';
import {
  SET_ALL_SEGMENTS,
  ADD_SEGMENT,
  REMOVE_SEGMENT_FROM_LIST,
  RESET_STORE,
} from '../mutation-types';

// Define a teams schema
const segmentSchema = new schema.Entity('segments');
const segmentListSchema = [segmentSchema];

// initial state
const state = {
  all: {},
  list: [],
};

// getters
const getters = {
  allSegments: (state) => state.list.map((segmentId) => state.all[segmentId]),
  mySavedSegments: (state, getters, rootState) => getters.allSegments.filter((segment) => segment.status === 'active' && segment.isSaved === true
    && segment.createdUserId === rootState.users.loggedInUser.id),
  segmentList: (state) => state.list,
  segmentById: (state) => (segmentId) => state.all[segmentId] || false,
  segmentDescription: (state, getters, rootState) => (segmentId) => {
    if (state.all[segmentId].createdUserId === rootState.users.loggedInUser.id
      && state.all[segmentId].name) {
      return state.all[segmentId].name;
    }
    return `${state.all[segmentId].users.length} users`;
  },
  uniqueUserCount: (state, getters, rootState, rootGetters) => (userIds = [], emails = [], segmentIds = []) => { // eslint-disable-line
    const segmentUserIdArrays = segmentIds.map((segmentId) => state.all[segmentId].users);
    let segmentUserIds = [].concat(...segmentUserIdArrays);
    segmentUserIds = Array.from(new Set(segmentUserIds));
    const notUsers = emails.filter((email) => rootGetters.doesEmailExist(email) === false);
    const fullList = [].concat(segmentUserIds, notUsers, userIds);
    return Array.from(new Set(fullList)).length;
  },
};

// actions (async, commit mutations)
const actions = {
  async setAllSegments({ dispatch, commit, rootState }, segments) {
    try {
      const segmentData = segments || await segmentService.getAll(rootState.users.loggedInUser.id);
      const normalized = normalize(segmentData, segmentListSchema);
      commit(SET_ALL_SEGMENTS, {
        all: normalized.entities.segments || {},
        list: normalized.result,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem setting your saved segments');
    }
  },
  async createSegment({ commit, dispatch, rootState }, data) {
    const segmentData = data;
    segmentData.teamId = rootState.teams.activeTeamId;
    let createdSegment = null;
    try {
      createdSegment = await segmentService.createSegment(segmentData);
      if (segmentData.emails.length > 0) {
        await dispatch('setProfilesOnTeam');
      }
      commit(ADD_SEGMENT, createdSegment);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem saving the segment');
      return null;
    }
    return createdSegment.id;
  },
  async removeSegment({ commit, dispatch }, segmentId) {
    try {
      await segmentService.removeOne(segmentId);
      commit(REMOVE_SEGMENT_FROM_LIST, segmentId);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem removing the segment');
    }
  },
};

// mutations (modify state)
const mutations = {
  [SET_ALL_SEGMENTS](state, segmentData) {
    state.all = segmentData.all;
    state.list = segmentData.list;
  },
  [ADD_SEGMENT](state, theSegment) {
    state.all[theSegment.id] = theSegment;
    state.list.push(theSegment.id);
  },
  [REMOVE_SEGMENT_FROM_LIST](state, segmentId) {
    state.all[segmentId].status = 'archived';
  },
  [RESET_STORE](state) {
    state.all = {};
    state.list = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
