import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { version } from '../../package.json';

const isEnabled = !!process.env.VUE_APP_SENTRY_DSN;

function setUser(user) {
  if (isEnabled && user) {
    const {
      id, lastLogin, status, teams,
    } = user;

    Sentry.configureScope((scope) => {
      scope.setUser({
        user: {
          id, lastLogin, status, version,
        },
        teams,
      });
    });
  }
}

function init() {
  if (isEnabled) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      environment: process.env.VUE_APP_ENV,
      release: version,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    });
  }
}

export default {
  setUser,
  init,
};
