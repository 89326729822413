<template>
  <!-- eslint-disable-next-line vue-a11y/form-has-label -->
  <textarea class="fs-block"
    ref="textarea"
    @input="$emit('input', $event.target.value)"
    :value="value"
    :class="{ 'is-valid': state, 'is-invalid':state === false }"
    :rows="rows"/>
</template>

<script>
export default {
  name: 'ExpandingTextArea',

  props: {
    value: { required: true },
    state: { type: Boolean },
    rows: { type: Number, default: 1 },
  },

  mounted() {
    const el = this.$refs.textarea;
    el.setAttribute('style', `min-height:${el.scrollHeight}px;overflow-y:hidden;`);
  },

  watch: {
    value() {
      this.$nextTick(() => this.resize());
    },
  },

  methods: {
    resize() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
    },
  },
};
</script>
