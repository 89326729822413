<template>
  <section>
    <div class="container-fluid h-100 mt-5">
      <div class="row pb-5">
        <div class="col ml-5">
          <div class="flight-info row mt-5">
            <FlightSidebarName :flight="selectedFlight" class="col mr-5"/>
            <FlightSidebarStage :flight="selectedFlight" class="col-3 mr-5"/>
            <FlightSidebarParticipation :flight="selectedFlight" class="col"/>
          </div>
          <div
            v-for="(q, index) in allQuestions"
            :key="'question-' + q.id"
            class="my-5">
            <h3 class="mb-5">Q{{index+1}} - {{q.text}}</h3>
            <div class="col">
              <PostFlightBalloonList
                class="p2"
                :balloons="balloonsForQ(q.id)"
                :presentation-mode="true"
                :print-mode="true"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import PostFlightBalloonList from '@/components/PostFlight/PostFlightBalloonList.vue';
import FlightSidebarName from '@/components/FlightSidebar/FlightSidebarName.vue';
import FlightSidebarStage from '@/components/FlightSidebar/FlightSidebarStage.vue';
import FlightSidebarParticipation from '@/components/FlightSidebar/FlightSidebarParticipation.vue';

export default {
  name: 'FlightResultsPrintView',

  components: {
    FlightSidebarName,
    FlightSidebarStage,
    FlightSidebarParticipation,
    PostFlightBalloonList,
  },

  props: {
    selectedFlight: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      'allQuestions',
      'balloonsForQ',
    ]),

    authorName() {
      return this.balloon.author.show
        ? this.userNameById(this.balloon.createdUserId)
        : 'Anonymous';
    },
  },

  created() {
    setTimeout(() => { window.print(); }, 1500);
  },
};
</script>

<style lang="less" scoped>
.flight-info {
  & section { padding-top: 0 }

  & ::v-deep h5 {
    color: @navy;
    text-transform: uppercase;
  }

  & ::v-deep .participant-list {
    display: none;
  }
}

@media print {
  section {
    padding: 0;
  }

  ::v-deep .balloon {
    padding: 1rem;
  }
}
</style>
