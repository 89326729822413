import '@babel/polyfill'; // eslint-disable-line
import Vue from 'vue';
import Vuebar from 'vuebar';
import Toasted from 'vue-toasted';
import VueScrollTo from 'vue-scrollto';
import BootstrapVue from 'bootstrap-vue';
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker';
import GloboUI from '@balloonplatform/globo-ui';
import '@balloonplatform/globo-ui/dist/globo.css';

import 'url-search-params-polyfill';

import 'vue-svgicon/dist/polyfill'; // For IE browsers
import * as SvgIcon from 'vue-svgicon';

// TODO: Move to global
import ExpandingTextarea from '@/components/ExpandingTextarea.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import axe from '@/services/axe';
import axios from '@/services/axios';
import sentry from '@/services/sentry';

import GlobalComponents from '@/plugins/global-components';
import GlobalDirectives from '@/plugins/global-directives';
import loadAnalytics from '@/analytics';
import globalPromiseRejectionHandler from './utils/globalPromiseRejectionHandler';

loadAnalytics();

axe.init();
axios.init();
sentry.init();

Vue.use(BootstrapVue);
Vue.use(Toasted, {
  position: 'bottom-center',
  className: 'toast',
  duration: 5000,
  action: {
    class: 'toast-close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});

Vue.use(Vuebar);
Vue.use(VueScrollTo);
Vue.use(SvgIcon, { isOriginalDefault: true });
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(GloboUI);

// define datepicker options
const datepickerOptions = {
  sundayFirst: true,
  colors: {
    disabled: '#F4F4F6',
  },
};
Vue.use(AirbnbStyleDatepicker, datepickerOptions);

Vue.component('PrimaryButton', PrimaryButton);
Vue.component('ExpandingTextarea', ExpandingTextarea);

Vue.config.productionTip = false;
window.onunhandledrejection = globalPromiseRejectionHandler;

store.dispatch('startTime');

function setGlobalWindowVars() {
  window.BalloonrVersion = process.env.VUE_APP_VERSION;
}

setGlobalWindowVars();

window.analytics.ready(() => {
  store.dispatch('analyticsReady');
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
