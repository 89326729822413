/* globals Modernizr */

const isiOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
const isDev = process.env.VUE_APP_ENV === 'development';

export default () => !isDev && (!Modernizr.es5array
  || !Modernizr.es5object
  || !Modernizr.es5function
  || !Modernizr.es5date
  || !Modernizr.flexbox
  || window.innerWidth < 768 || window.innerHeight < 400 || isiOS);
