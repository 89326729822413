import { loadStripe } from '@stripe/stripe-js';

const defaultStyles = {
  base: {
    backgroundColor: 'transparent',
    height: '36px',
    color: '#1d1d44',
    fontFamily: 'Avenir',
    fontSmoothing: 'antialiased',
    fontWeight: 550,
    paddingTop: '10px',
    fontSize: '1rem',
    '::placeholder': {
      color: '#1d1d44',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const stripePublicKey = process.env.VUE_APP_STRIPE_KEY;

export default {
  service: null,

  async stripe() {
    if (!this.service) {
      this.service = await loadStripe(stripePublicKey);
    }

    return this.service;
  },

  async createCardElement(style = defaultStyles) {
    try {
      const stripe = await this.stripe();
      const elements = stripe.elements();
      return elements.create('card', { style });
    } catch (err) {
      throw Error(`API error at stripeService.createCardElement: ${err.message}`);
    }
  },

  async createSource(card) {
    try {
      const stripe = await this.stripe();
      const sourceData = await stripe.createSource(card);

      if (sourceData.error) {
        throw Error(sourceData.error.message);
      }
      return sourceData;
    } catch (err) {
      throw Error(`API error at stripeService.createSource: ${err.message}`);
    }
  },
};
