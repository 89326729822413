import { render, staticRenderFns } from "./BalloonCommentForm.vue?vue&type=template&id=39617857&scoped=true&"
import script from "./BalloonCommentForm.vue?vue&type=script&lang=js&"
export * from "./BalloonCommentForm.vue?vue&type=script&lang=js&"
import style0 from "./BalloonCommentForm.vue?vue&type=style&index=0&id=39617857&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39617857",
  null
  
)

export default component.exports