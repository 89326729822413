<template>
  <section class="row">
    <div class="col">
      <h5 class="mb-4">Flight</h5>

      <h1>{{ flight.name }}</h1>
      <p>
        <ParseUrls :text="flight.description" />
        <b class="d-block mt-2">{{ authorName }}</b>
      </p>

      <div class="row">
        <div class="col">
          <button
            v-if="canExport"
            class="primary"
            @click="exportCSV"
            data-tour="export-results-csv"
          >
            Export CSV
          </button>
        </div>
        <div class="col">
          <button
            v-if="canExport"
            class="primary"
            @click="goToPrintResults()"
            data-tour="export-results-print"
          >
            Print/Save PDF
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ParseUrls from '@/components/ParseUrls.vue';

export default {
  name: 'FlightSidebarName',
  props: {
    flight: { type: Object, required: true },
    canExport: { type: Boolean, default: false },
  },
  components: {
    ParseUrls,
  },

  computed: {
    ...mapGetters(['userNameById']),

    authorName() {
      return this.flight.author.show
        ? this.userNameById(this.flight.createdUserId)
        : 'Anonymous';
    },
  },

  methods: {
    ...mapActions(['exportCSV']),

    goToPrintResults() {
      const route = this.$router.resolve({ name: 'print-results' });
      window.open(route.href, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@balloonplatform/client-lib/lib/less/text.less";

h1 {
  .h3();
}

p {
  white-space: pre-line;
}

button {
  margin-top: 3rem;
  padding: 1rem 1.4rem;
  width: 100%;
}

section {
  padding-top: 4rem;
}
</style>
