import stripe from '@/services/stripe';

export default {
  name: 'stripe',

  directive: {
    async inserted(el, { value }) {
      const card = await stripe.createCardElement();
      card.mount(el);

      if (typeof value.onChange === 'function') {
        value.onSet(card);
      }

      card.addEventListener('change', (event) => {
        if (typeof value.onChange === 'function') {
          value.onChange(event);
        }
      });
    },
  },
};
