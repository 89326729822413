import { mapGetters } from 'vuex';

export default {

  computed: {
    ...mapGetters([
      'currentStage',
      'isCredentialed',
    ]),

    stage() {
      return this.currentStage(this.flight
        ? this.flight.id
        : this.selectedFlight?.id);
    },

    isStageOne() {
      return this.stage === 'one';
    },

    isStageTwo() {
      return this.stage === 'two';
    },

    isPostFlight() {
      return this.stage === 'postFlight';
    },

    authorName() {
      return this.balloon.author.show
        ? this.userNameById(this.balloon.createdUserId)
        : 'Anonymous';
    },
  },
};
