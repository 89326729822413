<template>
  <div class="main container-fluid d-flex flex-column">
    <banner v-for="b in allBanners"
      :key="b.id"
      :id="b.id"
    />
    <top-bar />
    <main class="row flex-grow-1 flex-shrink-1">
      <router-view/>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Banner from '@/components/Layout/Banner.vue';
import TopBar from '@/components/Layout/TopBar.vue';

export default {
  name: 'MainLayout',
  components: { Banner, TopBar },

  computed: {
    ...mapGetters([
      'isAdmin',
      'isTrialSubscription',
      'loggedInUserRequiresConfirmation',
      'allBanners',
      'daysLeftInTrial',
      'activeTeamStripeInfo',
    ]),
  },

  watch: {
    activeTeamStripeInfo: {
      handler() {
        if (this.isAdmin && this.isTrialSubscription) { this.trialBanner(); }
      },
    },
    loggedInUserRequiresConfirmation: {
      handler() {
        if (this.loggedInUserRequiresConfirmation) { this.confirmAccountBanner(); }
      },
    },
  },

  mounted() {
    this.precacheImages();
    if (this.isAdmin && this.isTrialSubscription) {
      this.trialBanner();
    } else if (this.loggedInUserRequiresConfirmation) {
      this.confirmAccountBanner();
    }
  },

  methods: {
    precacheImages() {
      const sources = [
        '/images/icons/preferences.svg',
        '/images/icons/reset_password.svg',
        '/images/icons/help_center.svg',
        '/images/icons/go_to_flight_arrow_hover.svg',
        '/images/icons/open_details.svg',
        '/images/icons/preferences_hover.svg',
        '/images/icons/reset_password_hover.svg',
        '/images/icons/help_center_hover.svg',
        '/images/icons/next_arrow_hover.svg',
        '/images/icons/previous_arrow_hover.svg',
        '/images/icons/remove.svg',
        '/images/icons/download_more_actions_light.svg',
        '/images/icons/export_more_actions_light.svg',
      ];

      const images = [];
      for (let i = 0; i < sources.length; i += 1) {
        images[i] = new Image();
        images[i].src = sources[i];
      }
    },

    trialBanner() {
      if (this.allBanners.find((b) => b.id === 'trial')) {
        return;
      }

      this.$store.dispatch('infoBanner', {
        message: `You have ${this.daysLeftInTrial}
                  day${this.daysLeftInTrial !== 1 ? 's' : ''}
                  left in your free trial!`,
        to: { name: 'team' },
        id: 'trial',
        dismissible: true,
      });
    },
    confirmAccountBanner() {
      if (this.allBanners.find((b) => b.id === 'confirm-account')) {
        return;
      }
      this.$store.dispatch('infoBanner', {
        message: 'User role has been updated. To apply this change please check for an account confirmation email and follow the instructions.',
        id: 'confirm-account',
        dismissible: false,
      });
    },
  },
};
</script>
