<template>
  <div class="balloon-actions d-flex flex-column align-items-start pl-3 mt-2">
    <template v-if="isMine && isOneActive">
      <button
        class="secondary"
        @click="deleteBalloon"
        data-tour="delete-balloon">
        <icon name="remove" height="7" width="7" />
        Delete Balloon
      </button>
    </template>
    <template v-else-if="isPostFlight">
      <button
        v-if="canClaimBalloon && !presentationMode"
        class="secondary"
        :class="{ 'is-claimed': isClaimed }"
        data-tour="claim-balloon"
        @click="toggleBalloonClaim">
        <icon name="claim_checkmark" height="7" width="7" />
        {{ isClaimed ? 'Claimed' : 'Claim Balloon' }}
      </button>
    </template>
  </div>
</template>

<script>
import balloon from '@/mixins/balloon';

export default {
  name: 'BalloonActions',
  mixins: [balloon],

  props: {
    presentationMode: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async deleteBalloon() {
      this.isPendingChanges = true;
      try {
        await this.$store.dispatch('deleteBalloon', this.balloon.id);
      } catch (err) {
        throw Error('Error deleting balloon');
      } finally {
        this.isPendingChanges = false;
      }
    },

    async toggleBalloonClaim() {
      try {
        await this.$store.dispatch('toggleClaimBalloon', this.balloon.id);
      } catch (e) {
        throw Error('There was a problem claiming your balloon');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.balloon-actions {
  button {
    ::v-deep svg {
      &.remove {
        path {
          fill: @green;
        }
      }
    }

    &.is-claimed {
      color: @teal;
      ::v-deep svg {
        path {
          stroke: @teal;
        }
      }
    }
  }
}
</style>
