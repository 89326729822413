import Vue from 'vue';
import Router from 'vue-router';

import {
  publicRoutes, signupRoutes, flightRoutes, errorRoutes,
} from '@/router/routes';
import { checkAuth, trackPage } from '@/router/guards';

// see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
      return {};
    }
    return Promise.reject(err);
  });
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...publicRoutes,
    ...signupRoutes,
    ...flightRoutes,
    ...errorRoutes,
  ],
});

router.beforeEach(checkAuth);
router.afterEach(trackPage);

export default router;
