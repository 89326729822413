/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */
import isAfter from 'date-fns/is_after';
import differenceInWeeks from 'date-fns/difference_in_weeks';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import subWeeks from 'date-fns/sub_weeks';
import subDays from 'date-fns/sub_days';
import subHours from 'date-fns/sub_hours';
import * as localStorageUtil from '@/utils/localStorage';

import {
  UPDATE_TIME,
  NEW_TOAST,
  SHOW_LEAVE_GUARD,
  CLEAR_LEAVE_GUARD,
  NEW_BANNER,
  CLOSE_BANNER,
  CLEAR_BANNERS,
  ANALYTICS_READY,
  RESET_STORE,
} from '../mutation-types';

// initial state
const state = {
  now: new Date(),
  toasts: [],
  showLeaveGuard: false,
  okHandler: null,
  okText: '',
  cancelText: '',
  cancelHandler: null,
  leaveGuardTo: '',
  leaveGuardMessage: '',
  banners: [],
  analyticsReady: false,
};

// getters
const getters = {
  allToasts: (state) => state.toasts,
  allBanners: (state) => state.banners,
  bannerById: (state) => (bannerId) => state.banners.find((banner) => banner.id === bannerId) || '',
  validateEmail: () => (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
  // validate pw: at least 8 chars with 1 lowercase, 1 uppercase, 1 special char, 1 number
  validatePassword: () => (pw) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(pw),
  timeDiffInWords: () => (dateOne, dateTwo) => {
    const result = [];
    // set earlier date
    const one = isAfter(dateOne, dateTwo) ? new Date(dateTwo) : new Date(dateOne);
    // set later date
    let two = isAfter(dateOne, dateTwo) ? new Date(dateOne) : new Date(dateTwo);

    // if under a minute, just return seconds
    if (differenceInSeconds(two, one) < 60) {
      return `${differenceInSeconds(two, one)} seconds`;
    }

    const weeks = differenceInWeeks(two, one);
    if (weeks > 0) {
      result.push(`${weeks}W`);
      two = subWeeks(two, weeks);
    }

    const days = differenceInDays(two, one);
    if (days > 0) {
      result.push(`${days}D`);
      two = subDays(two, days);
    }

    const hours = differenceInHours(two, one);
    if (hours > 0) {
      result.push(`${hours}H`);
      two = subHours(two, hours);
    }

    const minutes = differenceInMinutes(two, one);
    if (minutes > 0) {
      result.push(`${minutes}M`);
    }

    return result.slice(0, 2).join(' '); // 2 weeks 3 days 8 hours 47 minutes
  },
};

// actions (async, commit mutations)
const actions = {
  // starts the interval that updates the now timer every second
  startTime({ commit }) {
    setInterval(() => {
      commit(UPDATE_TIME);
    }, 1000);
  },
  errorToast({ commit }, message) {
    commit(NEW_TOAST, {
      message,
      options: {
        type: 'error',
      },
    });
  },
  infoToast({ commit }, message) {
    commit(NEW_TOAST, {
      message,
      options: {
        type: 'info',
      },
    });
  },
  successToast({ commit }, message) {
    commit(NEW_TOAST, {
      message,
      options: {
        type: 'success',
      },
    });
  },
  logErrorToFS({ state }, message) {
    if (process.env.VUE_APP_ENV === 'production' && state.analyticsReady) {
      /* eslint-disable */
      window.FS.log('info', `Balloonr App version: ${BalloonrVersion}`);
      window.FS.log('error', message);
    }
  },
  showLeaveGuard({ commit }, info) {
    commit(SHOW_LEAVE_GUARD, info);
  },
  clearLeaveGuard({ commit }) {
    commit(CLEAR_LEAVE_GUARD);
  },
  errorBanner({ commit }, info) {
    commit(NEW_BANNER, {
      message: info.message,
      to: info.to,
      id: info.id,
      type: 'error',
      dismissible: info.dismissible ? info.dismissible : false,
    });
  },
  infoBanner({ commit }, info) {
    commit(NEW_BANNER, {
      message: info.message,
      to: info.to,
      id: info.id,
      type: 'info',
      dismissible: info.dismissible ? info.dismissible : false,
    });
  },
  successBanner({ commit }, info) {
    commit(NEW_BANNER, {
      message: info.message,
      to: info.to,
      id: info.id,
      type: 'success',
      dismissible: info.dismissible ? info.dismissible : false,
    });
  },
  closeBanner({ commit }, bannerId) {
    const banner = state.banners.find(b => b.id === bannerId);
    if (banner?.dismissible) {
      localStorageUtil.closeBanner(banner);
      return;
    }
    commit(CLOSE_BANNER, bannerId);
  },
  analyticsReady({ commit }) {
    if (process.env.VUE_APP_ENV === 'production' && window.FS) {
      /* eslint-disable */
      window.FS.log('info', `Balloonr App version: ${BalloonrVersion}`);
    }
    localStorageUtil.setUTMParams();
    localStorageUtil.setTemplateAuthorReferral();
    commit(ANALYTICS_READY);
  },
};

// mutations (modify state)
const mutations = {
  [UPDATE_TIME](state) {
    state.now = new Date();
  },
  [NEW_TOAST](state, newToast) {
    state.toasts.push(newToast);
  },
  [SHOW_LEAVE_GUARD](state, info) {
    state.leaveGuardTo = info.to;
    state.leaveGuardMessage = info.message;
    state.okHandler = info.okHandler;
    state.okText = info.okText,
    state.cancelText = info.cancelText,
    state.cancelHandler = info.cancelHandler;
    state.showLeaveGuard = true;
  },
  [CLEAR_LEAVE_GUARD](state) {
    state.showLeaveGuard = false;
    state.okHandler = null;
    state.okText = '',
    state.cancelText = '',
    state.cancelHandler = null;
    state.leaveGuardTo = '';
    state.leaveGuardMessage = '';
  },
  [NEW_BANNER](state, newBanner) {
    state.banners.push(newBanner);

    if (newBanner.dismissible === true) {
      localStorageUtil.setBanner(newBanner);
    }
  },
  [CLOSE_BANNER](state, bannerId) {
    state.banners.splice(state.banners.findIndex(b => b.id === bannerId), 1);
  },
  [CLEAR_BANNERS](state) {
    state.banners = [];
  },
  [ANALYTICS_READY](state) {
    state.analyticsReady = true;
  },
  [RESET_STORE](state) {
    state.showLeaveGuard = false;
    state.leaveGuardTo = '';
    state.leaveGuardMessage = '';
    state.banners = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
