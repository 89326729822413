import Vue from 'vue';

export default {
  async getLoggedInUser() {
    try {
      const response = await Vue.axios.get('api/v1/user');
      if (response.data === null) {
        throw Error('API error at userService.getLoggedInUser: response is null');
      }
      return response.data;
    } catch (err) {
      throw Error(`API error at userService.getLoggedInUser: ${err.message}`);
    }
  },
  async getAllUsersOnTeam(teamId) {
    if (!teamId) throw Error('No teamId passed to teamService.getAllUsersOnTeam');
    try {
      const response = await Vue.axios.get(`api/v1/teams/${teamId}/users`);
      return response.data;
    } catch (err) {
      throw Error('error trying to get all users for team');
    }
  },
  async getAllProfilesOnTeam(teamId) {
    if (!teamId) throw Error('No teamId passed to teamService.getAllProfilesOnTeam');
    try {
      const response = await Vue.axios.get(`api/v1/teams/${teamId}/profiles`);
      return response.data;
    } catch (err) {
      throw Error(`API error at userService.getAllProfilesOnTeam: ${err.message}`);
    }
  },
  async resendInvite(data) {
    if (!data.teamId) throw Error('No teamId passed to teamService.resendInvite');
    try {
      const response = await Vue.axios.post(`api/v1/teams/${data.teamId}/resend_invite`, { email: data.email });
      return response.data;
    } catch (err) {
      throw Error(`API error at userService.resendInvite: ${err.message}`);
    }
  },
  async saveNotificationPrefs(data) {
    if (!data.userId) throw Error('No userId passed to teamService.saveNotificationPrefs');
    try {
      const patchData = { notifications: data.notifications };
      const response = await Vue.axios.patch(`api/v1/users/${data.userId}/preferences`, patchData);
      return response.data;
    } catch (err) {
      throw Error(`API error at userService.saveNotificationPrefs: ${err.message}`);
    }
  },
  async updateTeamMembers(teamId, changes) {
    try {
      const response = await Vue.axios.post(`api/composite/team/${teamId}/members`, changes);
      const rs = response.data.responses;
      return {
        subscription: rs.filter((item) => item.ref.endsWith('/subscription'))[0]?.body ?? {},
      };
    } catch (err) {
      throw Error(`API error at userService.updateTeamMembers: ${err.message}`);
    }
  },
};
