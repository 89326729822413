<template>
  <section
    class="row"
    :data-index="(balloonIndex || 0) + 1"
    :class="{ 'post-flight':  isPostFlight}"
  >
    <div class="col balloon">
      <div class="row">
        <BalloonPump
          class="col-1"
          v-if="isPostFlight || isTwoActive"
          :balloon="balloon"
          :is-top="balloonIndex === 0"/>

        <div class="col">
          <BalloonText
            :balloon="balloon"
            :show-comments="showComments"
            :comment-mode="commentMode"
            @toggle-comments="toggleComments"/>

          <div class="row mt-2" v-show="isPostFlight || isMine">
            <BalloonAuthor
              class="col"
              :author-name="authorName"
              :show-author="isPostFlight"
              :show-is-mine="isMine"/>
          </div>

          <BalloonStats
            v-if="isPostFlight"
            class="row mt-5"
            :balloon="balloon"
            :comment-mode="commentMode"
            :show-comments="showComments"
            @toggle-comments="isPrintModeActive"
          />

          <div class="row mt-4 d-flex flex-column" v-show="showComments">
            <Separator v-show="showComments"/>
            <div :class="isPostFlight ? 'col': 'col-11'">
              <transition name="fade">
                <BalloonCommentList
                  v-show="showComments"
                  :balloon="balloon"
                  :is-one-active="isOneActive"
                  :comment-mode="commentMode"
                  :presentation-mode="presentationMode" />
              </transition>
            </div>
          </div>

          <div class="row" v-show="commentMode && showCommentForm && isOneActive">
            <div class="col">
              <transition name="fade">
                <BalloonCommentForm
                  v-show="commentMode && showCommentForm"
                  ref="comment-form"
                  :balloon-id="balloon.id"
                  :comment-mode="commentMode"/>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BalloonActions
      v-if="!printMode"
      class="col-2"
      :balloon="balloon"
      :presentation-mode="presentationMode" />
  </section>
</template>

<script>
import balloon from '@/mixins/balloon';

import BalloonText from '@/components/Balloon/BalloonText.vue';
import BalloonPump from '@/components/Balloon/BalloonPump.vue';
import BalloonAuthor from '@/components/Balloon/BalloonAuthor.vue';
import BalloonStats from '@/components/Balloon/BalloonStats.vue';
import BalloonActions from '@/components/Balloon/BalloonActions.vue';
import BalloonCommentForm from '@/components/Balloon/BalloonCommentForm.vue';
import BalloonCommentList from '@/components/Balloon/BalloonCommentList.vue';

export default {
  name: 'Balloon',
  mixins: [balloon],

  components: {
    BalloonText,
    BalloonPump,
    BalloonStats,
    BalloonAuthor,
    BalloonActions,
    BalloonCommentForm,
    BalloonCommentList,
  },

  props: {
    presentationMode: {
      type: Boolean,
      default: false,
    },

    printMode: {
      type: Boolean,
      default: false,
    },

    balloonIndex: {
      type: Number,
    },
  },

  data() {
    return {
      showComments: false,
      showCommentForm: false,
      isPendingChanges: false,
    };
  },

  computed: {
    isPrintModeActive() {
      return this.printMode ? false : this.toggleComments;
    },
  },

  mounted() {
    if (this.printMode) { this.showComments = true; }
  },

  methods: {
    toggleComments() {
      this.showComments = !this.showComments;
      this.showCommentForm = !this.showCommentForm;

      if (this.showCommentForm) {
        this.$nextTick(() => {
          this.$refs['comment-form'].focusInput();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep svg {
  path {
    fill: none;
  }
}

.balloon {
  border-radius: 1px;
  border: solid 1px rgba(29, 29, 68, 0.2);
  background: @white;
  margin-bottom: 1rem;
  padding: 1.8rem 4rem 2.4rem 1.8rem;
  width: 100%;
}
</style>
