<template>
  <button :class="{ pending: pending, primary }">
    <span v-show="!pending">
      <slot></slot>
    </span>
    <svg v-show="pending" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="9" fill="none" :class="primary ? 'white' : 'green'"
        stroke-linecap="round" stroke-width="2" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    pending: { type: Boolean, default: false },
    primary: { type: Boolean, default: true },
  },
};
</script>

<style lang="less" scoped>
button {
  position: relative;

  &.outline {
    background: transparent;
    color: @green;
    border: 1px solid @green;
  }

  circle {
    stroke-dasharray: 58;
    stroke-dashoffset: 116;
    animation: load-circle 2s ease-out forwards infinite;

    &.green {
      stroke: @green;
    }

    &.white {
      stroke: @white;
    }
  }

  @keyframes load-circle {
    100%{
      stroke-dashoffset: 0;
    }
  }

  &.pending,
  &.pending * {
    color: transparent;
  }
}
</style>
