<template>
  <div>
    <div class="col d-flex justify-content-between">
      <div >
        <p class="stat">
          Pump Score
          <em>+{{ results.pumpScore }}</em>
        </p>

        <p class="stat">
          Approval
          <em>{{ results.approval }}%</em>
        </p>

        <p class="stat">
          Percentile
          <em>{{ results.percentile }}%</em>
        </p>
      </div>

      <BalloonCommentToggle
        v-if="hasComments && (commentMode || !isOneActive)"
        :show-comments="showComments"
        :comments-count="balloon.comments.length"
        data-tour="toggle-comments-stage-2"
        @toggle-comments="toggleComments"
      />

    </div>
  </div>
</template>

<script>
import balloon from '@/mixins/balloon';

import BalloonCommentToggle from '@/components/Balloon/BalloonCommentToggle.vue';

export default {
  name: 'BalloonStats',
  mixins: [balloon],

  components: { BalloonCommentToggle },

  props: {
    showComments: { type: Boolean, default: false },
  },

  methods: {
    toggleComments() {
      this.$emit('toggle-comments');
    },
  },
};
</script>

<style lang="less" scoped>
  .stat {
    height: 30px;
    border-radius: 1px;
    color: @green;
    font-weight: 500;
    background-color: @medium-tan;
    display: inline-block;
    color: @green;
    margin-right: 1rem;
    padding: .4rem 1.2rem 0 1.2rem;
    font-size: 1rem;
    line-height: 1.1;

    em {
      margin-left: 2rem;
      font-size: 1.6rem;
      font-weight: normal;
      letter-spacing: .8px;
      color: @green;
    }
  }
</style>
