<template>
  <div></div>
</template>

<script>
export default {
  name: 'Separator',
};
</script>

<style lang="less" scoped>
  div {
    border-top: 1px solid @light-navy;
    margin-bottom: 1.6rem;
    margin-left: 1.5rem;
    margin-right: 5rem;
  }
</style>
