<template>
  <div class="tip">
    <icon name="info-circle" color="orange" width="12" height="12"/>
    <span :class="color">
      <b v-if="showTitle">Tip</b>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Tip',

  props: {
    showTitle: { type: Boolean, default: true },
    color: { type: String, default: '' },
  },
};
</script>

<style lang="less" scoped>
.tip {
  display: flex;

  b {
    display: block;
  }

  span {
    flex: 1;
    margin-left: .6rem;
    font-size: 1rem;
    line-height: 1.6;
    color: @navy;
  }

  svg {
    margin-top: .15rem;
  }
}
</style>
