<template>
  <b-collapse
    class="balloon-comment-list ml-4"
    :id="'comments-' + balloon.id"
    v-model="showComments"
    v-if="commentMode || !isOneActive">
      <section
        aria-live="polite"
        aria-relevant="additions"
        aria-atomic="false"
        class="balloon-comment-list-item"
        v-for="comment in balloon.comments"
        :key="comment.id"
        :class="{ 'is-post-flight': isPostFlight }">
          <p class="balloon-comment-list-item-text">{{ comment.text }}</p>
          <div class="d-flex justify-content-between">
            <BalloonAuthor
            :author-name="commentAuthorName(comment)"
            :show-author="isPostFlight"
            :is-comment="true"
            :show-is-mine="isMyComment(comment)"/>

            <button
              v-if="canClaimComment(comment)"
              data-tour="claim-comment"
              class="secondary"
              :class="{ 'is-claimmed': isCommentClaimed(comment) }"
              @click="toggleCommentClaim(comment)">
                <icon name="claim_checkmark" height="7" width="7" />
                {{ isCommentClaimed(comment) ? "Claimed" : "Claim Comment" }}
            </button>
        </div>
      </section>
    </b-collapse>
</template>

<script>
import { mapGetters } from 'vuex';

import BalloonAuthor from '@/components/Balloon/BalloonAuthor.vue';

export default {
  name: 'BalloonCommentList',
  components: { BalloonAuthor },

  props: {
    balloon: { type: Object },
    isOneActive: { type: Boolean, default: false },
    commentMode: { type: Boolean, default: false },
    presentationMode: { type: Boolean, default: false },
  },

  data() {
    return {
      showComments: true,
    };
  },

  computed: {
    ...mapGetters([
      'currentStage',
      'loggedInUser',
      'isCredentialed',
      'userNameById',
    ]),

    isPostFlight() {
      return this.currentStage(this.balloon.flightId) === 'postFlight';
    },
  },

  methods: {
    isMyComment(comment) {
      return (
        comment.createdUserId === this.loggedInUser.id && !this.presentationMode
      );
    },

    canClaimComment(comment) {
      return (
        this.isCredentialed && this.isPostFlight && this.isMyComment(comment)
      );
    },

    isCommentClaimed(comment) {
      return comment.author.show;
    },

    commentAuthorName(comment) {
      return comment.author.show
        ? this.userNameById(comment.author.profile.userId)
        : 'Anonymous';
    },

    async toggleCommentClaim(comment) {
      try {
        await this.$store.dispatch('toggleClaimComment', {
          balloonId: this.balloon.id,
          commentId: comment.id,
        });
      } catch (e) {
        throw Error('There was a problem claiming your comment');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.balloon-comment-list {
  width: 100%;

  &.is-post-flight {
    margin-left: 11rem;
  }

  &-item {
    &:not(:first-child) {
      border-top: 1px solid @light-navy;
      margin-top: 1.6rem;
      padding-top: 1.6rem;
    }

    &-text {
      white-space: pre-wrap;
      font-size: 1.1rem;
      line-height: 1.80;
    }

    button {
      &.is-claimmed {
        color: @teal;
        ::v-deep svg {
          path {
            stroke: @teal;
            fill: none;
          }
        }
      }
    }
  }
}
</style>
