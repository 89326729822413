import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import store from '@/store';
import router from '@/router/index';
import { rootApiUrl } from '@/config';

function init() {
  Vue.use(VueAxios, axios);
  Vue.axios.defaults.baseURL = rootApiUrl;
  Vue.axios.interceptors.request.use((config) => {
    const reqConfig = config;
    reqConfig.headers['Cache-Control'] = 'no-cache';
    reqConfig.headers.Pragma = 'no-cache';

    if (store.getters.isLoggedIn) {
      reqConfig.headers.Authorization = store.state.auth.token;
    }
    if (reqConfig.method === 'patch') {
    // Axios does not correctly assign the 'Content-Type' header from
    // interceptors or defaults. This header must be assigned per-request.
    // (see https://github.com/axios/axios/issues/362)
      reqConfig.headers['Content-Type'] = 'application/json-patch+json';
    }
    return reqConfig;
  });

  Vue.axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response.status === 401) {
        if (store.getters.isLoggedIn) {
          store.dispatch(
            'logErrorToFS',
            `401 Error while isLoggedIn: ${error.message}`,
          );
          store.dispatch('logout');
          window.location.reload();
          router.push('/logout');
          return;
        }

        router.push('/error/401');
        return;
      }

      if (error?.response.status === 403) {
        const err = {
          code: error.response.data.error.code,
          message: error.response.data.error.detail,
        };
        throw err;
      }

      if (error.response.data.error) {
        if (error.response.data.error.detail) {
          throw new Error(error.response.data.error.detail);
        }

        if (error.response.data.error.message) {
          throw new Error(error.response.data.error.message);
        }
      }

      throw error;
    },
  );
}

export default {
  init,
};
