import Vue from 'vue';

export default {
  async getAll() {
    try {
      const response = await Vue.axios.get('api/composite/templates');
      const rs = response.data.responses;

      return {
        authors: rs.filter((item) => item.ref.includes('/templates/authors'))[0]?.body ?? [],
        series: rs.filter((item) => item.ref.includes('/templates/series'))[0]?.body ?? [],
        templates: rs.filter((item) => item.ref.endsWith('/templates'))[0]?.body ?? [],
      };
    } catch (err) {
      throw Error(`API error at flightTemplateService.getAll: ${err.message}`);
    }
  },
};
