/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { normalize, schema } from 'normalizr';
import teamService from '../../services/team';
import subscriptionService from '../../services/subscription';

import {
  SET_ALL_TEAMS,
  SET_ACTIVE_TEAM,
  SET_TEAM_SUBSCRIPTION,
  RESET_STORE,
} from '../mutation-types';
import * as localStorageUtil from '../../utils/localStorage';
import { getTeamId } from '../../utils/userToken';

// Define a teams schema
const teamSchema = new schema.Entity('teams');
const teamListSchema = [teamSchema];

// initial state
const state = {
  all: {},
  list: [],
  activeTeamId: null,
  stripeSubscription: null,
  stripeCustomer: null,
  stripeSource: null,
  stripeNextInvoice: null,
};

// getters
const getters = {
  activeTeam: (state) => state.all[state.activeTeamId],
  allTeams: (state) => state.list.map((teamId) => state.all[teamId]),
  billingMethod: (state) => state.all[state.activeTeamId].subscription.billingMethod,
  activeTeamStripeInfo: (state) => ({
    subscription: state.stripeSubscription,
    customer: state.stripeCustomer,
    source: state.stripeSource,
    nextInvoice: state.stripeNextInvoice,
  }),
  isActiveSubscription: (state, getters, rootState) => {
    const { now } = rootState.utils;
    const { validUntilDate } = state.all[state.activeTeamId].subscription;
    return now < new Date(validUntilDate);
  },
  isTrialSubscription: (state) => state.stripeSubscription?.status === 'trialing',
  isSubscriptionCanceled: (state) => state.stripeSubscription?.cancel_at_period_end === true,
  daysLeftInTrial: (state, getters, rootState) => {
    if (getters.isTrialSubscription) {
      const { now } = rootState.utils;
      const trialEnd = format(state.stripeSubscription?.trial_end * 1000); // eslint-disable-line
      return differenceInCalendarDays(trialEnd, now);
    }
    return '';
  },
  isSamlAuth: (state) => state.all[state.activeTeamId].authentication.method === 'saml',
  allowsExternalContribution: (state, getters) => getters.isSamlAuth
    && state.all[state.activeTeamId].configuration.allowExternalContributors, // eslint-disable-line
  allowedDomains: (state) => {
    const domains = state.all[state.activeTeamId].authentication.samlIDP
      ?.authenticatedDomains;
    return domains || '';
  },
  isExternalDomain: (state, getters) => (email) => {
    if (email.includes('@')) {
      const emailDomain = email.split('@').pop();
      return !getters.allowedDomains.includes(emailDomain);
    }

    return false;
  },
};

// actions (async, commit mutations)
const actions = {

  async setAllTeams({
    dispatch, commit, rootState,
  }, teams) {
    try {
      const teamData = teams || (await teamService.getAll(rootState.users.loggedInUser.id));

      const normalized = normalize(teamData, teamListSchema);
      commit(SET_ALL_TEAMS, {
        all: normalized.entities.teams,
        list: normalized.result,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', "There was a problem fetching your team's data");
    }
  },

  setActiveTeam({
    dispatch, commit,
  }, teamId) {
    let activeTeamId;
    try {
      activeTeamId = teamId || getTeamId();
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem setting the active team');
    }

    window.analytics.group(activeTeamId);
    window.analytics.identify({
      company: {
        id: activeTeamId,
        name: state.all[activeTeamId].name,
      },
      activeTeamId,
      teamName: state.all[activeTeamId].name,
    });

    localStorageUtil.updateActiveTeam(activeTeamId);
    commit(SET_ACTIVE_TEAM, activeTeamId);
  },

  async setTeamSubscription({ dispatch, commit }, teamId) {
    try {
      const data = await subscriptionService.getTeamSubscription(teamId);
      commit(SET_TEAM_SUBSCRIPTION, data);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch(
        'errorToast',
        "There was a problem fetching your team's subscription data",
      );
    }
  },

  async cancelTeamSubscription({ dispatch, commit }, teamId) {
    try {
      const data = await subscriptionService.cancelTeamSubscription(teamId);
      commit(SET_TEAM_SUBSCRIPTION, data);
      dispatch('successToast', 'Your subscription has been canceled');
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch(
        'errorToast',
        "There was a problem canceling your team's subscription",
      );
    }
  },

  async restartTeamSubscription({ dispatch }, teamId) {
    try {
      await subscriptionService.restartTeamSubscription(teamId);
      dispatch('successToast', 'Your subscription has been restarted');
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch(
        'errorToast',
        "There was a problem restarting your team's subscription",
      );
    }
  },
};

// mutations (modify state)
const mutations = {
  [SET_ALL_TEAMS](state, teamData) {
    state.all = teamData.all;
    state.list = teamData.list;
  },
  [SET_ACTIVE_TEAM](state, teamId) {
    state.activeTeamId = teamId;
  },
  [SET_TEAM_SUBSCRIPTION](
    state,
    {
      subscriptionData, customerData, paymentData, nextInvoiceData,
    },
  ) {
    state.stripeSubscription = subscriptionData;
    state.stripeCustomer = customerData;
    state.stripeSource = paymentData;
    state.stripeNextInvoice = nextInvoiceData;
  },
  [RESET_STORE](state) {
    state.all = {};
    state.list = [];
    state.activeTeamId = null;
    state.stripeSubscription = null;
    state.stripeCustomer = null;
    state.stripeSource = null;
    state.stripeNextInvoice = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
