import Vue from 'vue';
import { getUTMParams } from '@/utils/localStorage';
import { rootApiUrl } from '@/config';

export default {
  async login(creds) {
    try {
      const response = await Vue.axios.post('api/composite/login', creds);
      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');

      const rs = response.data.responses;
      return {
        user: rs.filter((item) => item.ref.includes('/login'))[0]?.body ?? {},
        teams: rs.filter((item) => item.ref.includes('/teams'))[0]?.body ?? [],
        token: sessionToken,
      };
    } catch (err) {
      throw Error(`API error at authService.login: ${err.message}`);
    }
  },
  async samlLogin(queryParams) {
    try {
      let samlLoginUrl = `${rootApiUrl}/api/auth/saml/login/${queryParams.email}`;

      if (queryParams.flightId) {
        samlLoginUrl = `${samlLoginUrl}?flightId=${queryParams.flightId}`;
      }

      window.location.href = samlLoginUrl;
    } catch (err) {
      throw Error(`API error at authService.samlLogin: ${err.message}`);
    }
  },
  async loginLink(creds) {
    try {
      const response = await Vue.axios.post('api/auth/login/link', creds);
      if (response.status !== 200) {
        throw Error(
          'API error at authService.loginLink: response status not 200',
        );
      }
    } catch (err) {
      throw Error(`API error at authService.loginLink: ${err.message}`);
    }
  },
  async requestPassword(creds) {
    try {
      const response = await Vue.axios.post('api/auth/request_password', creds);
      if (response.status !== 200) {
        throw Error(
          'API error at authService.requestPassword: response status not 200',
        );
      }
    } catch (err) {
      throw Error(`API error at authService.requestPassword: ${err.message}`);
    }
  },
  async logout(token) {
    try {
      const headers = { Authorization: `Bearer ${token}` };
      return Vue.axios.post('api/auth/logout', {}, { headers });
    } catch (err) {
      throw Error(`API error at authService.logout: ${err.message}`);
    }
  },
  async validateShortcode(data) {
    try {
      const response = await Vue.axios.post(
        'api/auth/begin_shortcode_validation',
        data,
      );
      if (response.status !== 200) {
        throw Error(
          'API error at authService.validateShortcode: response status not 200',
        );
      }
      return response;
    } catch (err) {
      throw Error(`API error at authService.validateShortcode: ${err.message}`);
    }
  },
  async completeShortcode(data) {
    try {
      const response = await Vue.axios.post(
        'api/auth/complete_shortcode_validation',
        data,
      );
      if (response.status !== 200) {
        throw Error(
          'API error at authService.completeShortcode: response status not 200',
        );
      }
      let sessionToken;
      if (response.headers.authorization) {
        sessionToken = response.headers.authorization;
        sessionToken.replace('Bearer ', '');
      }
      return { ...response.data, token: sessionToken };
    } catch (err) {
      throw Error(`API error at authService.completeShortcode: ${err.message}`);
    }
  },
  async validateCode({ email, code, isSignup = false }) {
    const utmParams = getUTMParams();
    try {
      const response = await Vue.axios.patch(
        `api/auth/verification/complete${utmParams}`,
        { email, code, isSignup },
      ); //
      if (response.status !== 200) {
        throw Error(
          'API error at authService.validateShortcode: response status not 200',
        );
      }
      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');
      return { user: response.data, token: sessionToken };
    } catch (err) {
      throw Error(`API error at authService.validateShortcode: ${err.message}`);
    }
  },
  async changePassword(data) {
    const options = {};
    if (data.token) {
      options.headers = { Authorization: `Bearer ${data.token}` };
    }
    try {
      const response = await Vue.axios.post(
        'api/auth/change_password',
        { password: data.password },
        options,
      );
      if (response.status !== 200) {
        throw Error(
          'API error at authService.changePassword: response status not 200',
        );
      }
      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');
      return { token: sessionToken };
    } catch (err) {
      throw Error(`API error at authService.changePassword: ${err.message}`);
    }
  },
  async tokenCheck(data) {
    const options = {};
    if (data.token) {
      options.headers = { Authorization: `Bearer ${data.token}` };
    }
    try {
      const response = await Vue.axios.post(
        'api/auth/check_token',
        { scope: data.scope },
        options,
      );
      if (response.status !== 200) {
        throw Error(
          'API error at authService.tokenCheck: response status not 200',
        );
      }
    } catch (err) {
      throw Error(`API error at authService.tokenCheck: ${err.message}`);
    }
  },
  async completeAccountConfirmation(data) {
    if (!data.token) throw Error('No token passed to authService.completeAccountConfirmation');
    const headers = { Authorization: `Bearer ${data.token}` };
    try {
      const response = await Vue.axios.patch(
        'api/auth/complete_invite',
        { ...data.payload },
        { headers },
      );
      if (response.status !== 200) {
        throw Error(
          'API error at authService.completeAccountConfirmation: response status not 200',
        );
      }
      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');
      return { user: response.data, token: sessionToken };
    } catch (err) {
      throw Error(
        `API error at authService.completeAccountConfirmation: ${err.message}`,
      );
    }
  },
  async completeAccountClaim(data) {
    if (!data.token) throw Error('No token passed to authService.completeAccountClaim');
    const headers = { Authorization: `Bearer ${data.token}` };
    try {
      const response = await Vue.axios.patch(
        'api/v1/users/claim',
        { ...data.payload },
        { headers },
      );
      const sessionToken = response.headers.authorization;
      sessionToken.replace('Bearer ', '');
      return { user: response.data, token: sessionToken };
    } catch (err) {
      throw Error(
        `API error at authService.completeAccountConfirmation: ${err.message}`,
      );
    }
  },
  async changeTeam(data) {
    try {
      const response = await Vue.axios.post('api/auth/change_team', data);
      if (response.status !== 200) {
        throw Error(
          'API error at userService.changeTeam: response status not 200',
        );
      }
      let sessionToken;
      if (response.headers.authorization) {
        sessionToken = response.headers.authorization;
        sessionToken.replace('Bearer ', '');
      }
      return sessionToken;
    } catch (err) {
      throw Error(`API error at authService.changeTeam: ${err.message}`);
    }
  },
  async checkTeamDomain(teamDomain) {
    try {
      const response = await Vue.axios.get(`api/auth/teams/domain/${teamDomain}`);
      if (response.status !== 200) {
        return false;
      }
      return true;
    } catch (err) {
      throw Error(`API error at authService.checkTeamDomain: ${err.message}`);
    }
  },
};
