/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */

import { normalize, schema } from 'normalizr';
import flightTemplateService from '../../services/flightTemplate';
import {
  SET_FLIGHT_TEMPLATES,
  SET_ACTIVE_FLIGHT_TEMPLATE,
  CLEAR_ACTIVE_FLIGHT_TEMPLATE,
  SET_FLIGHT_TEMPLATE_AUTHORS,
  SET_FLIGHT_TEMPLATE_SERIES,
  RESET_STORE,
} from '../mutation-types';

// Define a flightTemplate schema
const flightTemplateSchema = new schema.Entity('flightTemplates');
const templateListSchema = [flightTemplateSchema];

// Define an author schema
const authorSchema = new schema.Entity('authors');
const authorListSchema = [authorSchema];

// Define a series schema
const seriesSchema = new schema.Entity('series');
const seriesListSchema = [seriesSchema];

// initial state
const state = {
  all: {},
  list: [],
  activeTemplateId: '',
  allAuthors: {},
  allSeries: {},
};

// getters
const getters = {
  allFlightTemplates: (state) => state.list.map((templateId) => state.all[templateId]),
  activeFlightTemplate: (state) => state.all[state.activeTemplateId] || {},
  activeTemplateAuthor: (state, getters) => state.allAuthors[getters.activeFlightTemplate.templateAuthorId],// eslint-disable-line
  activeTemplateSeries: (state) => {
    const seriesId = Object.keys(state.allSeries).find(id => state.allSeries[id].templateIds.includes(state.activeTemplateId)) // eslint-disable-line
    return state.allSeries[seriesId] || {};
  },

  flightTemplateCategories: (state, getters) => [...new Set([]
    .concat(...getters.allFlightTemplates.map((template) => template.categories)))].sort(),

  allFlightTemplateSlugs: (state) => {
    const slugs = {};
    state.list.forEach((templateId) => {
      slugs[templateId] = state.all[templateId].slug;
    });
    return slugs;
  },
  flightTemplateIdBySlug: (state, getters) => (slug) => Object.keys(getters.allFlightTemplateSlugs)
    .find((id) => getters.allFlightTemplateSlugs[id] === slug),
  flightTemplateById: (state) => (templateId) => state.all[templateId],

  allTemplateSeriesSlugs: (state) => {
    const slugs = {};
    Object.keys(state.allSeries).forEach((seriesId) => {
      slugs[seriesId] = state.allSeries[seriesId].slug;
    });
    return slugs;
  },
  templateSeriesIdBySlug: (state, getters) => (slug) => Object.keys(getters.allTemplateSeriesSlugs)
    .find((id) => getters.allTemplateSeriesSlugs[id] === slug),
  templateSeriesById: (state) => (seriesId) => state.allSeries[seriesId],

  allTemplateAuthorsNames: (state) => {
    const names = {};
    Object.keys(state.allAuthors).forEach((authorId) => {
      names[authorId] = state.allAuthors[authorId].fullName;
    });
    return names;
  },
  templateAuthorById: (state) => (authorId) => state.allAuthors[authorId],
  templateAuthorsBySeriesId: (state, getters) => (seriesId) => {
    const authors = new Set(
      state.allSeries[seriesId].templateIds.map((tId) => {
        const template = getters.flightTemplateById(tId);
        return getters.templateAuthorById(template.templateAuthorId);
      }),
    );
    return [...authors];
  },
};

// actions (async, commit mutations)
const actions = {
  async setTemplates({ dispatch, commit }) {
    try {
      const templatesData = await flightTemplateService.getAll();
      const normalizedTemplates = normalize(templatesData.templates, templateListSchema);
      commit(SET_FLIGHT_TEMPLATES, {
        all: normalizedTemplates.entities.flightTemplates || {},
        list: normalizedTemplates.result,
      });
      const normalizedAuthors = normalize(templatesData.authors, authorListSchema);
      commit(SET_FLIGHT_TEMPLATE_AUTHORS, {
        allAuthors: normalizedAuthors.entities.authors,
      });
      const normalizedSeries = normalize(templatesData.series, seriesListSchema);
      commit(SET_FLIGHT_TEMPLATE_SERIES, {
        allSeries: normalizedSeries.entities.series,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
    }
  },
  setActiveFlightTemplate({ commit, state }, templateId) {
    if (state.list.includes(templateId)) {
      commit(SET_ACTIVE_FLIGHT_TEMPLATE, templateId);
    }
  },
  clearActiveFlightTemplate({ commit }) {
    commit(CLEAR_ACTIVE_FLIGHT_TEMPLATE);
  },
};

// mutations (modify state)
const mutations = {
  [SET_FLIGHT_TEMPLATES](state, templatesData) {
    state.all = templatesData.all;
    state.list = templatesData.list;
  },
  [SET_ACTIVE_FLIGHT_TEMPLATE](state, templateId) {
    state.activeTemplateId = templateId;
  },
  [CLEAR_ACTIVE_FLIGHT_TEMPLATE](state) {
    state.activeTemplateId = '';
  },
  [SET_FLIGHT_TEMPLATE_AUTHORS](state, authorsData) {
    state.allAuthors = authorsData.allAuthors;
  },
  [SET_FLIGHT_TEMPLATE_SERIES](state, seriesData) {
    state.allSeries = seriesData.allSeries;
  },
  [RESET_STORE](state) {
    state.all = {};
    state.list = [];
    state.activeTemplateId = '';
    state.allAuthors = {};
    state.allSeries = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
