import Vue from 'vue';

export default {
  async getAllForFlight(flightId) {
    if (!flightId) {
      throw Error('No flightId passed to balloonService.getAllForFlight');
    }
    try {
      const response = await Vue.axios.get(`api/v1/flights/${flightId}/balloons`);
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.getAllForFlight: ${err.message}`);
    }
  },
  async createBalloon(data) {
    if (!data.flightId) {
      throw Error('No flightId passed to balloonService.createBalloon');
    }
    try {
      const response = await Vue.axios.post(`api/v1/flights/${data.flightId}/balloons`, data.balloon);
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.createBalloon: ${err.message}`);
    }
  },
  async deleteBalloon(balloonId) {
    if (!balloonId) {
      throw Error('No balloonId passed to balloonService.deleteBalloon');
    }
    try {
      await Vue.axios.delete(`api/v1/balloons/${balloonId}`);
    } catch (err) {
      throw Error(`API error at balloonService.deleteBalloon: ${err.message}`);
    }
  },
  async pumpBalloon(balloonId) {
    if (!balloonId) {
      throw Error('No balloonId passed to balloonService.pumpBalloon');
    }
    try {
      const response = await Vue.axios.post(`api/v1/balloons/${balloonId}/pumps`);
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.pumpBalloon: ${err.message}`);
    }
  },
  async createComment(data) {
    if (!data.balloonId) {
      throw Error('No balloonId passed to balloonService.createComment');
    }
    try {
      const response = await Vue.axios.post(`api/v1/balloons/${data.balloonId}/comments`, { text: data.text });
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.createComment: ${err.message}`);
    }
  },
  async toggleClaimBalloon(balloonId) {
    if (!balloonId) {
      throw Error('No balloonId passed to balloonService.toggleClaimBalloon');
    }
    try {
      const response = await Vue.axios.post(`api/v1/balloons/${balloonId}/claim`);
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.toggleClaimBalloon: ${err.message}`);
    }
  },
  async toggleClaimComment(data) {
    if (!data.balloonId) {
      throw Error('No balloonId passed to balloonService.toggleClaimComment');
    }
    if (!data.commentId) {
      throw Error('No commentId passed to balloonService.toggleClaimComment');
    }
    try {
      const response = await Vue.axios.post(`api/v1/balloons/${data.balloonId}/${data.commentId}/claim`);
      return response.data;
    } catch (err) {
      throw Error(`API error at balloonService.toggleClaimComment: ${err.message}`);
    }
  },
};
