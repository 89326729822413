<template>
   <header id="top-bar" class="row flex-wrap justify-content-end flex-shrink-0 py-2">
      <div class="col-12 col-md-8 d-flex align-items-center py-4">
        <router-link id="logo" to="/flights">
          <icon
            name="balloon"
            height="20"
            width="94"
            color="medium-tan"
            class="ml-4"
            aria-label="Balloon"
          />
        </router-link>
        <div class="main-nav d-flex align-items-center">
          <router-link
            class="menu-item"
            to="/flights"
            v-if="isCredentialed"
            data-tour="main-nav-flights"
          >
            Flights
          </router-link>
          <router-link
            class="menu-item"
            to="/flights/templates"
            v-if="isCredentialed && hasCreatorPerms"
            data-tour="main-nav-templates"
          >
            Flight Templates
          </router-link>
        </div>
      </div>
      <div class="col-12 col-md-4 justify-content-end d-flex align-items-center">
        <div class="d-inline-block mr-5">
          <b-dropdown v-if="hasCreatorPerms" no-caret right
            data-tour="team-menu" menu-class="nav-dropdown" id="team-dropdown">
            <template slot="button-content">
              <div class="d-inline-block dropdown-selector d-flex align-items-center">
                <img class="d-inline-block mr-4 active-team-logo"
                  :src="activeTeam.logoUrl || '/images/icons/default_team_icon.svg'" alt=""/>
                <p class="menu-item d-inline-block white">{{activeTeam.name}}</p>
              </div>
            </template>
              <b-dropdown-item
                v-if="hasCreatorPerms"
                data-tour="segment-list"
                :to="{ name: 'segment-list' }">
                Segments
              </b-dropdown-item>
              <b-dropdown-item v-if="isAdmin"
                data-tour="team-list"
                :to="{ name: 'team' }">
                Team & Subscription
              </b-dropdown-item>
          </b-dropdown>
          <div v-else class="d-inline-block d-flex align-items-center">
            <img class="d-inline-block mr-4 active-team-logo"
              :src="activeTeam.logoUrl || '/images/icons/default_team_icon.svg'" alt="" />
            <h4 class="d-inline-block white">{{activeTeam.name}}</h4>
          </div>
        </div>
        <!-- <div class="d-inline-block mr-5">
          <b-dropdown no-caret right>
            <template slot="button-content">
              <img class="d-block hover-scale" src="/images/icons/notification_bell.svg" />
            </template>
            <div class="container-fluid notifications-panel">
              <div class="row">
                <span class="col info-label blue">Notifications</span>
              </div>
              <div v-for="n in 5" :key="n" class="row">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col d-flex align-items-center justify-content-center">
                      <h4 class="flex-grow-1">col item {{n}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-dropdown>
        </div> -->
        <div class="d-inline-block">
          <b-dropdown id="user-dropdown" ref="userMenu" no-caret menu-class="nav-dropdown down">
            <div class="user-email">
              <icon
                name="account"
                color="tan"
                class="mr-2"
                width="20"
                height="20"
                aria-label="Account Email"
              />
              {{loggedInUser.email}}
            </div>
            <div class="user-team-domain">
              Team Domain: {{loggedInTeamDomain}}
            </div>
            <b-dropdown-item :to="{ name: 'notification-preferences'}">
              Notification Preferences
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'change-password' }" v-if="isCredentialed && !isSamlAuth">
              Reset Password
            </b-dropdown-item>
            <b-dropdown-item href="https://help.balloon.app" target="_blank">
              Help Center
            </b-dropdown-item>
            <b-dropdown-item to="/logout">
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="d-inline-block ml-5" v-if="!isCredentialed">
          <router-link
            tag="button"
            class="primary bg-orange"
            :to="{ name: 'claim-account', params: { token: token, claimAccount: true } }"
          >
            Claim Your Account
          </router-link>
        </div>
      </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { getToken } from '@/utils/localStorage';

export default {
  name: 'TopBar',

  mounted() {
    // This is a dirty fix that adds missing aria label to an internal vue-boostrap button
    const { userMenu } = this.$refs;
    if (userMenu && userMenu.$refs.toggle) {
      userMenu.$refs.toggle.setAttribute('aria-label', 'Manage Account');
    }
  },

  computed: {
    ...mapGetters([
      'allTeams',
      'activeTeam',
      'loggedInUser',
      'isLoggedIn',
      'isAdmin',
      'isCredentialed',
      'hasCreatorPerms',
      'isSamlAuth',
    ]),

    loggedInTeamDomain() {
      return this.activeTeam?.loginDomain;
    },

    token() { return getToken(); },
  },
};
</script>

<style lang="less" scoped>
@import "~@balloonplatform/client-lib/lib/less/text.less";

header {
  background: @navy;
}

.main-nav {
  margin-left: 8vw;

  a {
    color: @light-tan;
    font-size: 1.4rem;
    letter-spacing: 1.6px;
    margin-left: 3vw;
    border-bottom: 2px solid transparent;

    &.router-link-exact-active,
    &:hover { border-bottom: 2px solid @pink; }
  }
}

.active-team-logo {
  height: 20px;
  width: 20px;
}

::v-deep #team-dropdown {
  position: relative;

  ::v-deep .dropdown-menu {
    position: absolute;
    top: 9px !important;
    min-width: 280px;
    left: 55px !important;
  }

  .dropdown-selector {
    background: url('/images/icons/dropdown_close_light.svg') @navy no-repeat;
  }

  .dropdown-selector {
    transition: .3s;
    background: url('/images/icons/dropdown_open_light.svg') @navy no-repeat;
  }
}

::v-deep #user-dropdown {
  width: 45px;
  height: 25px;
  ::v-deep .dropdown-menu {
    top: 17px !important;
    min-width: 320px;
    left: 5px !important;
  }

  .dropdown-toggle {
    width: 45px;
    height: 25px;
    background: url('/images/icons/account-dropdown.svg') @navy no-repeat;
    &:hover{
      background: url('/images/icons/account-dropdown-hover.svg') @navy no-repeat;
    }
  }

  .user-email {
    background-color: @navy;
    margin: 0 -30px;
    min-height: 50px;
    color: @light-tan;
    padding: 1.6rem 3rem;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.6;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .user-team-domain {
    background-color: @navy;
    margin: 0 -30px;
    font-weight: 800;
    color: @medium-navy;
    padding: 0 0 0 6rem;
    min-height: 40px;
    font-size: 1.3rem;
    line-height: 1.6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
  }

  li:last-of-type {
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-top: 1px solid @medium-tan;
  }
}

.notifications-panel {
  min-width: 500px;
}

.counter {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  background-color: @orange;

  h2 {
    color: @white;
    margin: 0 auto;
  }
}
</style>
