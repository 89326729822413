<template>
  <svgicon
    :name="name"
    :class="`svg-${color} ${name}`"
    :width="width"
    :height="height"
    :style="style"
  ></svgicon>
</template>

<script>
export default {
  name: 'icon',

  props: {
    name: { type: String, required: true },
    preload: { type: Array, default: () => [] },
    width: { type: String, default: '12' },
    height: { type: String, default: '12' },
    color: { type: String, default: 'green' },
    stroke: { type: Boolean, default: false },
  },

  computed: {
    style() {
      return this.stroke ? {} : { stroke: 'none' };
    },
  },

  created() {
    this.preload.concat(this.name).forEach(name => import(/* webpackChunkName: "icon-[request]" */ '../Icons/' + name)); // eslint-disable-line
  },
};
</script>

<style lang="less">
@import "~@balloonplatform/client-lib/lib/less/color.less";

.svg-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
  transition: 0.1s transform ease-in-out;
}

.svg-fill {
  fill: currentColor;
  stroke: none;
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}

.svg-navy {
  stroke: @navy;
  path { fill: @navy; }
}

.svg-white{
  stroke: @white;
  path { fill: @white; }
}

.svg-green{
  stroke: @green;
  path { fill: @green; }
}

.svg-teal{
  stroke: @teal;
  path { fill: @teal; }
}

.svg-orange{
  stroke: @orange;
  path { fill: @orange; }
}

.svg-tan{
  stroke: @tan;
  path { fill: @tan; }
}

.svg-medium-tan{
  stroke: @medium-tan;
  path { fill: @medium-tan; }
}

.svg-light-tan{
  stroke: @light-tan;
  path { fill: @light-tan; }
}

.svg-pink {
  stroke: @pink;
  path { fill: @pink; }
}
</style>
