import Vue from 'vue';

function init() {
  const enableAxe = process.env.VUE_APP_ENABLE_AXE === 'true';

  if (enableAxe && process.env.NODE_ENV !== 'production') {
    import(/* webpackChunkName: "vue-axe" */ 'vue-axe')
      .then((VueAxe) => Vue.use(VueAxe, { clearConsoleOnUpdate: false }));
  }
}

export default { init };
