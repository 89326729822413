import store from '@/store';

export async function checkAuth(to, from, next) {
  // this is a fallback for links generated after history-mode support
  if (to.hash) {
    return next(to.hash.substring(1));
  }

  // immediately pass to next for logout calls
  if (to.path === '/logout') {
    return next();
  }

  // this route requires auth, check if logged in
  // if not logged in --> redirect to login page.
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // always refresh for saml auth
    if (to.query.authType && to.query.authType === 'saml') {
      const query = {
        email: to.query.email,
        flightId: to.params.id,
      };
      store.dispatch('logout');
      return next({ path: '/saml-login', query });
    }

    if (!store.getters.isLoggedIn) {
      const query = to.path === '/' ? {} : { redirectUrl: to.path };
      query.email = to.query.email;
      if (to.query.loginDomain) {
        query.loginDomain = to.query.loginDomain;
        return next({ path: '/manual-login', query });
      }
      return next({ path: '/login', query });
    }
    if (!store.getters.loggedInUser) {
      // token set, but loggedInUser (and all other data) not set, so initUser
      await store.dispatch('initUser');
    }
  }

  // this route requires credentialed login, but user does not have it
  if (to.matched.some((record) => record.meta.requiresCredentialedLogin)) {
    if (!store.getters.isCredentialed) {
      return next({ name: 'flight-detail' });
    }
  }

  // this route requires token login, but user does not have it or is already credentialed
  if (to.matched.some((record) => record.meta.requiresTokenLogin)) {
    if (store.getters.isCredentialed) {
      return next({ name: 'flights-list' });
    }
  }

  // this route requires admin access
  if (to.matched.some((record) => record.meta.requiresAdminAccess)) {
    if (!store.getters.isAdmin) {
      return next({ path: '/flights' });
    }
  }

  // this route requires standard access, but user does not have it
  if (to.matched.some((record) => record.meta.requiresStandardAccess)) {
    if (!store.getters.hasCreatorPerms) {
      return next({ path: '/flights' });
    }
  }

  // this route requires the active flight to have been created
  // by the flight creator.
  if (to.matched.some((record) => record.meta.requiresFlightCreator)) {
    const flightId = to.params.id;
    if (!store.getters.isMyFlight(flightId)) {
      return next({ path: '/flights' });
    }
  }

  // this route requires an active subscription
  if (to.matched.some((record) => record.meta.requiresActiveSubscription)) {
    if (!store.getters.isActiveSubscription) {
      return next({ path: '/flights' });
    }
  }

  // this route requires a team authentication method different from SSO
  if (to.matched.some((record) => record.meta.requiresNotSSOLogin)) {
    if (store.getters.isSamlAuth) {
      return next({ path: '/flights' });
    }
  }

  // route doesn't require auth, but isLoggedIn --> redirect to flights
  if (
    store.getters.isLoggedIn
    && to.matched.every((record) => !record.meta.requiresAuth)
  ) {
    if (to.name === 'error-mobile') {
      return next();
    }
    if (to.name === 'flightLinkLoginView' || to.name === 'signup') {
      return next();
    }
    if (to.name === 'shortcode') {
      await store.dispatch('initUser');
      return next();
    }
    if (to.name === 'confirm-account') {
      store.dispatch('logout');
      return next();
    }
    return next({ name: 'root' });
  }
  // passed all checks, onto next!
  return next();
}

export function trackPage(to) {
  if (window.analytics) {
    const { fullPath, params, query } = to;
    window.analytics.page({
      path: fullPath,
      params,
      query,
      url: `${window.location.protocol}://${window.location.host}${fullPath}`,
    });
  }
}
