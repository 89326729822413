import { mapGetters } from 'vuex';

export default {
  props: {
    balloon: {
      type: Object,
      required: true,
    },

    commentMode: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters([
      'currentStage',
      'isMyBalloon',
      'myPumpsForBalloon',
      'userNameById',
      'isCredentialed',
      'balloonResults',
      'loggedInUser',
    ]),

    results() {
      const results = this.balloonResults(this.balloon.id);

      return {
        balloonScore: Math.round(results.balloonrScore || 0),
        pumpScore: results.totalPumps || 0,
        approval: Math.round(results.approval || 0),
        percentile: Math.round(results.percentile || 0),
      };
    },

    authorName() {
      return this.balloon.author.show
        ? this.userNameById(this.balloon.createdUserId)
        : 'Anonymous';
    },

    isMine() {
      return this.isMyBalloon(this.balloon.id) && !this.presentationMode;
    },

    canClaimBalloon() {
      return this.isCredentialed && this.isMine;
    },

    isClaimed() {
      return this.isMine && this.balloon.author.show;
    },

    hasComments() {
      return this.balloon.comments.length > 0;
    },

    calcPumps() {
      return (this.myPumpsForBalloon(this.balloon.id) + this.pendingPumps) % 3;
    },

    isOneActive() {
      return this.currentStage(this.balloon.flightId) === 'one';
    },

    isTwoActive() {
      return this.currentStage(this.balloon.flightId) === 'two';
    },

    isPostFlight() {
      return this.currentStage(this.balloon.flightId) === 'postFlight';
    },
  },
};
