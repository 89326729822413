<template>
  <label
    :for="`toggle-${_uid}`"
    tabindex="0"
    @keypress.enter="toggle"
    :class="{ 'disabled': disabled }"
  >
    <span class="title">
      <slot></slot>
    </span>
    <input
      :id="`toggle-${_uid}`"
      type="checkbox"
      :name="name"
      :disabled="disabled"
      :checked="value"
      @change="onChange">
    <span class="thumb"></span>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',

  props: {
    value: { type: [String, Boolean], required: true },
    name: { type: String },
    disabled: { type: Boolean, default: false },
  },

  methods: {
    onChange({ target }) {
      this.$emit('input', target.checked ? (this.value || true) : false);
    },

    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="less">
label {
  cursor: pointer;
  position: relative;

  input {
    display: none;
  }
}

.title {
  margin-right: 2.8rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.thumb {
  position: absolute;
  height: 6px;
  top: 4px;
  bottom: 3px;
  right: 0;
  border-radius: 3px;
  background-color: @medium-tan;
  transition: .25s;
  width: 20px;

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    height: 12px;
    width: 12px;

    border: 1px solid @navy;
    border-radius: 50%;

    background-color: @white;
    transition: .25s;
  }
}

input:checked + .thumb {
  background-color: @orange;
}

input:checked + .thumb:before {
  transform: translateX(8px);
}

.disabled {
  cursor: not-allowed;

  .thumb { opacity: 40%; }
}
</style>
