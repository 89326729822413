/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */

import {
  TRACK_BALLOON_LAUNCHED,
  TRACK_BALLOON_DELETED,
  TRACK_BALLOON_PUMPED,
  TRACK_BALLOON_CLAIMED,
  TRACK_BALLOON_UNCLAIMED,
  TRACK_COMMENT_ADDED,
  TRACK_COMMENT_CLAIMED,
  TRACK_COMMENT_UNCLAIMED,
} from '@balloonplatform/client-lib/lib/js/constants/analytics-constants';
import { normalize, schema } from 'normalizr';
import balloonService from '../../services/balloon';
import {
  SET_ALL_BALLOONS,
  ADD_BALLOON, ADD_COMMENT,
  UPDATE_BALLOON,
  DELETE_BALLOON,
  SET_BALLOON_RESULTS,
  RESET_STORE,
} from '../mutation-types';

// Define a balloons schema
const balloonSchema = new schema.Entity('balloons');
const balloonListSchema = [balloonSchema];

const state = {
  all: {},
  list: [],
  results: {},
};

const getters = {
  allBalloons: (state) => state.list.map((balloonId) => state.all[balloonId]),
  balloonById: (state) => (balloonId) => state.all[balloonId],
  balloonResults: (state) => (balloonId) => state.results[balloonId] || {},
  balloonsForQ: (state) => (questionId) => state.list
    .filter((balloonId) => state.all[balloonId].questionId === questionId)
    .map((balloonId) => state.all[balloonId]),
  isMyBalloon: (state, getters, rootState) => (balloonId) => state
    .all[balloonId].createdUserId === rootState.users.loggedInUser.id,
  myPumpsForBalloon: (state, getters, rootState) => (balloonId) => state
    .all[balloonId].pumps[rootState.users.loggedInUser.id] || 0,
  allPumpsForBalloon: (state) => (balloonId) => Object.values(state
    .all[balloonId].pumps).reduce((acc, cur) => acc + cur, 0),
  totalPumpsForQ: (state, getters) => (questionId) => {
    const arrOfPumpVals = getters.balloonsForQ(questionId)
      .map((balloon) => getters.allPumpsForBalloon(balloon.id));
    return arrOfPumpVals.reduce((acc, cur) => acc + cur, 0);
  },
};

const actions = {
  async getFlightBalloons({ dispatch, commit }, flightId) {
    try {
      const balloonData = await balloonService.getAllForFlight(flightId);
      const normalized = normalize(balloonData, balloonListSchema);
      const allBalloons = normalized.entities.balloons || {};
      commit(SET_ALL_BALLOONS, {
        all: allBalloons,
        list: normalized.result,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem fetching your balloons');
    }
  },
  async createBalloon({ dispatch, commit, rootGetters }, createBalloonData) {
    try {
      const newBalloon = await balloonService.createBalloon(createBalloonData);
      dispatch('addParticipation');
      commit(ADD_BALLOON, newBalloon);
      window.analytics.track(TRACK_BALLOON_LAUNCHED, {
        flightId: newBalloon.flightId,
        target: rootGetters.activeFlight.target,
      }, { groupId: newBalloon.teamId });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem creating your balloons');
    }
  },
  async deleteBalloon({ dispatch, commit, getters }, balloonId) {
    try {
      const deletedBalloon = getters.balloonById(balloonId);
      if (!deletedBalloon) {
        throw new Error('Balloon could not be found');
      }
      await balloonService.deleteBalloon(balloonId);
      commit(DELETE_BALLOON, deletedBalloon);
      window.analytics.track(TRACK_BALLOON_DELETED, {
        flightId: deletedBalloon.flightId,
        target: deletedBalloon.target,
      }, { groupId: deletedBalloon.teamId });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem deleting this balloon');
    }
  },
  async createComment({ dispatch, commit }, createCommentData) {
    try {
      const newComment = await balloonService.createComment(createCommentData);
      dispatch('addParticipation');
      commit(ADD_COMMENT, newComment);
      window.analytics.track(TRACK_COMMENT_ADDED, {
        flightId: newComment.flightId,
      }, { groupId: newComment.teamId });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem adding your comment');
    }
  },
  async pumpBalloon({ dispatch, commit, getters }, balloonId) {
    try {
      const updatedBalloon = await balloonService.pumpBalloon(balloonId);
      dispatch('addParticipation');
      commit(UPDATE_BALLOON, updatedBalloon);
      window.analytics.track(TRACK_BALLOON_PUMPED, {
        flightId: updatedBalloon.flightId,
        target: getters.balloonById(balloonId).target,
      }, { groupId: updatedBalloon.teamId });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem pumping this balloons');
    }
  },
  async toggleClaimBalloon({ dispatch, commit }, balloonId) {
    try {
      const updatedBalloon = await balloonService.toggleClaimBalloon(balloonId);
      commit(UPDATE_BALLOON, updatedBalloon);
      if (updatedBalloon.author.show) {
        window.analytics.track(TRACK_BALLOON_CLAIMED, {
          flightId: updatedBalloon.flightId,
        }, { groupId: updatedBalloon.teamId });
      } else {
        window.analytics.track(TRACK_BALLOON_UNCLAIMED, {
          flightId: updatedBalloon.flightId,
        }, { groupId: updatedBalloon.teamId });
      }
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem claiming/unclaiming your balloon');
    }
  },
  async toggleClaimComment({ dispatch, commit }, data) {
    try {
      const updatedBalloon = await balloonService.toggleClaimComment(data);
      commit(UPDATE_BALLOON, updatedBalloon);

      const comment = updatedBalloon.comments.find((c) => c.id === data.commentId);
      if (comment) {
        window.analytics.track(
          comment.author.show ? TRACK_COMMENT_CLAIMED : TRACK_COMMENT_UNCLAIMED,
          {
            flightId: updatedBalloon.flightId,
          }, { groupId: updatedBalloon.teamId },
        );
      }
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem claiming/unclaiming your comment');
    }
  },
};

const mutations = {
  [SET_ALL_BALLOONS](state, balloonData) {
    state.all = balloonData.all;
    state.list = balloonData.list;
  },
  [ADD_BALLOON](state, newBalloon) {
    state.all[newBalloon.id] = newBalloon;
    state.list.push(newBalloon.id);
  },
  [DELETE_BALLOON](state, balloon) {
    delete state.all[balloon.id];
    state.list.splice(state.list.indexOf(balloon.id), 1);
  },
  [ADD_COMMENT](state, newComment) {
    state.all[newComment.balloonId].comments.push(newComment);
  },
  [UPDATE_BALLOON](state, balloon) {
    state.all[balloon.id] = { ...balloon };
  },
  [SET_BALLOON_RESULTS](state, resultsData) {
    state.results = resultsData;
  },
  [RESET_STORE](state) {
    state.all = {};
    state.list = [];
    state.results = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
