<template>
  <div class="row">
    <div class="col-11">
      <p class="balloon-text" :class="{ 'is-hidden': isTextHidden }">
        <ParseUrls :text="balloon.text" />
      </p>
    </div>
    <div class="col d-flex justify-content-end align-items-start">
      <BalloonCommentToggle
        v-if="(isOneActive && commentMode) || (isTwoActive && balloon.comments.length)"
        :show-comments="showComments"
        :comments-count="balloon.comments.length"
        data-tour="toggle-comments"
        @toggle-comments="toggleComments"
      />
    </div>
  </div>
</template>

<script>
import balloon from '@/mixins/balloon';

import BalloonCommentToggle from '@/components/Balloon/BalloonCommentToggle.vue';
import ParseUrls from '@/components/ParseUrls.vue';

export default {
  name: 'BalloonText',
  mixins: [balloon],

  components: { BalloonCommentToggle, ParseUrls },

  props: {
    showComments: { type: Boolean, required: false },
  },

  computed: {
    isTextHidden() {
      return this.isOneActive && !this.isMine && !this.commentMode;
    },
  },

  methods: {
    toggleComments() {
      this.$emit('toggle-comments');
    },
  },
};
</script>

<style lang="less" scoped>
.balloon-text {
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;

    &.is-hidden {
      position: relative;
      box-decoration-break: clone;
      border-radius: 1px;
      background-color: @medium-tan;
      color: transparent;
      user-select: none;
      background-image: linear-gradient(@medium-tan 1.5rem, @white .7rem);
      background-size: 100% 2.2rem;

      ::v-deep img {
        opacity: 0;
      }
    }
  }
</style>
