<template>
  <div>
    <small v-if="showAuthor" class="balloon-author">{{ authorName }}</small>
    <small v-if="showIsMine" class="balloon-author is-mine">
      {{ isComment ? 'My Comment' : 'My Balloon'}}
    </small>
  </div>
</template>

<script>
export default {
  name: 'BalloonAuthor',

  props: {
    authorName: { type: String, default: '' },
    isComment: { type: Boolean, default: false },
    showAuthor: { type: Boolean, default: false },
    showIsMine: { type: Boolean, default: false },
  },
};
</script>

<style lang="less" scoped>
small {
  display: inline-block;
  font-weight: 900;
  margin-top: 1rem;
  margin-right: 1.2rem;

  &.is-mine {
    color: @teal;
  }
}
</style>
