<template>
  <main class="container-fluid" :class="bgColor">
    <a id="logo" href="https://getballoon.com">
      <icon width="140" height="30" name="balloon" :color="iconColor" />
    </a>
    <router-view />
  </main>
</template>

<script>
const DEFAULT_COLOR = 'navy';
export default {
  name: 'PublicLayout',

  data() {
    return {
      bgColor: DEFAULT_COLOR,
    };
  },

  computed: {
    iconColor() {
      return this.bgColor === 'navy' ? 'tan' : 'navy';
    },
  },

  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(newRoute) {
        this.bgColor = newRoute.meta && newRoute.meta.bgColor
          ? newRoute.meta.bgColor
          : DEFAULT_COLOR;
      },
    },
  },
};
</script>

<style lang="less" scoped>
main {
  overflow: auto;
  padding-bottom: 4rem;
}

#logo {
  > * {
    position: absolute;
    top: 40px;
    left: 40px;
  }
}

.navy {
  background-color: @navy;
  ::v-deep h1 {
    color: @tan;
  }
}

.medium-tan {
  background-color: @medium-tan;
  ::v-deep h1 {
    color: @navy;
  }
}

main {
  background-image: url('/images/logos/accent-logo.svg');
  background-repeat: no-repeat;
  background-position: 107% 100%;
}
</style>
