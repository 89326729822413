const globalPromiseRejectionHandler = (e) => {
  switch (e.reason.name) {
    case 'ChunkLoadError': {
      const refreshedAt = JSON.parse(window.sessionStorage.getItem('page-refreshed-at') || 'false');
      const hourDiff = refreshedAt ? Math.round(Math.abs(refreshedAt - Date.now()) / 36e5) : 0;
      if (!refreshedAt || hourDiff >= 2) {
        window.sessionStorage.setItem('page-refreshed-at', JSON.stringify(Date.now()));
        window.location.reload();
        break;
      }
      throw e;
    }
    default:
      break;
  }
};

export default globalPromiseRejectionHandler;
