<template>
  <section class="row">
    <div class="col">
      <h5 class="mb-4">Participation</h5>
      <ul>
        <li>
          <span class="label">Participants</span>
          <span class="instructions">{{participantCount}}</span>
        </li>
        <li>
          <span class="label">Stage 1</span>
          <span class="instructions">{{participationOne}}%</span>
        </li>
        <li>
          <span class="label">Stage 2</span>
          <span class="instructions">{{participationTwo}}%</span>
        </li>
        <li>
          <span class="label">Overall</span>
          <span class="instructions">{{participationOverall}}%</span>
        </li>
      </ul>
      <div v-if="isFlightCreator" class="participant-list mt-4">
        <p v-if="participantCount < 4">As the Flight Creator, you are able to view the list
          of participants once 3 people have participated.</p>
         <a v-else class="navy underline b-500" @click="toggleModal">See List of Participants</a>
         <b-modal
          v-model="showModal"
          hide-header
          hide-footer
          lazy
          centered
          no-close-on-backdrop
          bodyClass="p-4">
          <div class="close">
            <icon name="remove" color="medium-tan" @click.native="toggleModal" />
          </div>

          <h4 class="p-4">Participant List</h4>
          <ul class="p-4">
            <li
              v-for="(name, index) in participantList"
              :key="index"
            >
              {{ name }}
            </li>
          </ul>
        </b-modal>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlightSidebarParticipation',

  data() {
    return {
      showModal: false,
    };
  },

  props: {
    flight: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      'flightAudienceSize',
      'participation',
      'isMyFlight',
      'profileById',
    ]),
    participantCount() {
      return this.flightAudienceSize(this.flight.id);
    },

    participationOne() {
      return this.participation(this.flight.id).stageOne;
    },

    participationTwo() {
      return this.participation(this.flight.id).stageTwo;
    },

    participationOverall() {
      return this.participation(this.flight.id).overall;
    },

    isFlightCreator() {
      return this.isMyFlight(this.flight.id);
    },

    participantList() {
      const participants = this.flight.users.invited.userIds;
      const flightCreator = this.flight.createdUserId;

      return participants.filter((p) => p !== flightCreator)
        .map((p) => this.profileById(p).email)
        .sort();
    },
  },

  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  li {
    margin-bottom: 1.2rem;
    span {
      display: inline-block;
      width: 60px;
      margin-right: 2rem;
    }
  }
}

h4 {
  color: @tan;
  font-weight: 400;
  letter-spacing: .8px;
}
</style>
