/* eslint-disable */
function loadAnalytics() {
  var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
    analytics.invoked = !0; analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on"]; analytics.factory = function (t) { return function () { var e = Array.prototype.slice.call(arguments); e.unshift(t); analytics.push(e); return analytics } }; for (var t = 0; t < analytics.methods.length; t++) { var e = analytics.methods[t]; analytics[e] = analytics.factory(e) } analytics.load = function (t, e) { var n = document.createElement("script"); n.type = "text/javascript"; n.async = !0; n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js"; var a = document.getElementsByTagName("script")[0]; a.parentNode.insertBefore(n, a); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = "4.1.0";
    switch (process.env.VUE_APP_ENV) {
      case 'staging':
        analytics.load("5k8JClxOPZ4AjRilV0a1IfNU4sQkVk6v");
        break;
      case 'production':
        analytics.load("EkTdTzMBRDjoFeG8wzdsz7rBx5WsW8R7");
        break;
      default:
        // load for development
        analytics.load("Cjkt5SqGOiVmV6jDxPaZlfEmYtSLu0EJ");
    }
    analytics.page();
  }
};

export default loadAnalytics;