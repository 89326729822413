import { mapActions, mapState, mapGetters } from 'vuex';

export default {

  data() {
    return {
      isFlightLoading: false,
    };
  },

  computed: {
    ...mapState({
      activeFlightId: (state) => state.flights.activeFlightId,
    }),

    ...mapGetters([
      'canAccessResults',
      'activeFlight',
      'currentStage',
    ]),

    stage() {
      return this.activeFlightId ? this.currentStage(this.activeFlightId) : null;
    },
  },

  methods: {
    ...mapActions([
      'getActiveFlight',
      'getFlightResults',
      'getFlightBalloons',
      'setQuestions',
    ]),

    async fetchBalloonData() {
      if (this.stage === 'postFlight' && !this.canAccessResults(this.activeFlightId)) {
        return;
      }

      await this.getFlightBalloons(this.activeFlightId);
    },

    async fetchData() {
      try {
        this.setActiveFlight();
        await this.fetchFlightData();
        if (!this.editMode) {
          await this.fetchBalloonData();
        }
        // track stage
      } catch (err) {
        this.$store.dispatch('clearActiveFlight');
        this.$store.dispatch('errorToast', err.message);
        // catch err but do nothing otherwise router complains
        this.$router.push('/flights');
      }
    },

    async fetchDelayedData() {
      this.isFlightLoading = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await this.fetchData();
      this.isFlightLoading = false;
    },

    async fetchFlightData() {
      await this.getActiveFlight();
      this.setQuestions();
      this.setActiveQuestion();
      if (this.stage === 'postFlight' && this.canAccessResults(this.activeFlightId)) {
        await this.getFlightResults();
      }
    },

    setActiveFlight() {
      const flightId = this.$route.params.id;
      this.$store.dispatch('setActiveFlight', flightId);
    },

    setActiveQuestion() {
      const { q } = this.$route.params;
      if (!q) {
        return;
      }

      if (this.questionsList && this.questionsList.includes(q)) {
        this.$store.dispatch('setActiveQuestion', q);
        return;
      }

      this.$store.dispatch('setActiveQuestion', this.questionsList[0]);
    },
  },
};
