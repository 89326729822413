<template>
<div>
  <div
    v-if="isPostFlight"
    class="balloon-score"
    :class="{ 'top-balloon': isTop, 'top-score': results.balloonScore === 100 }">
    {{ results.balloonScore }}
  </div>
  <button
    v-if="isTwoActive"
    data-tour="pump-balloon"
    :class="{ zero: calcPumps === 0, one: calcPumps === 1, two: calcPumps === 2 }"
    @click="pumpBalloon">
    <span>+{{ calcPumps }}</span>
  </button>
</div>
</template>

<script>
import balloon from '@/mixins/balloon';

export default {
  name: 'BalloonPump',
  mixins: [balloon],

  props: {
    isTop: { type: Boolean, default: false },
  },

  data() {
    return {
      pendingPumps: 0,
    };
  },

  methods: {
    async pumpBalloon() {
      this.pendingPumps += 1;
      try {
        await this.$store.dispatch('pumpBalloon', this.balloon.id);
        this.pendingPumps -= 1;
      } catch (e) {
        this.pendingPumps -= 1;
        throw Error('Error pumping balloon');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.balloon-score {
    background: @teal;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    font-size: 2.2rem;
    font-weight: 900;
    color: @white;

    &.top-score {
      font-size: 1.8rem;
    }

    &.top-balloon {
      background: @green;
    }
  }

  button {
    height: 40px;
    width: 40px;
    margin-right: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    transition: none;

    span {
      color: @medium-tan;
      display: inline-block;
      font-size: 1rem;
      margin-top: -5px;
      font-weight: 900;
    }

    &.zero {
      background-image: url("/images/icons/pump_0.svg");
      span {
        display: none;
      }
    }

    &.one {
      background-image: url("/images/icons/pump_1.svg");
      color: @teal;
    }

    &.two {
      background-image: url("/images/icons/pump_2.svg");
      color: @green;
    }
  }
</style>
