import Vue from 'vue';

export default {
  async getAll(userId) {
    try {
      const response = await Vue.axios.get(`api/v1/users/${userId}/segments`);
      return response.data;
    } catch (err) {
      throw Error(`API error at segmentService.getAll: ${err.message}`);
    }
  },
  async createSegment(segmentData) {
    try {
      const response = await Vue.axios.post('api/v1/users/segments', segmentData);
      return response.data;
    } catch (err) {
      throw Error(`API error at segmentService.createSegment: ${err.message}`);
    }
  },
  async removeOne(segmentId) {
    try {
      const response = await Vue.axios.delete(`api/v1/segments/${segmentId}`);
      if (response.status !== 200) {
        throw Error('API error at segmentService.removeOne: response status not 200');
      }
    } catch (err) {
      throw Error(`API error at segmentService.removeOne: ${err.message}`);
    }
  },
};
