import Vue from 'vue';

export default {
  async getAll(teamId) {
    if (!teamId) {
      throw Error('No teamId passed to flightService.getAll');
    }
    try {
      const response = await Vue.axios.get(`api/composite/flights/${teamId}`, {
        // Do not reject 403 status, to catch tokens without access to flight
        // status >= 200 && status < 300;
        validateStatus: (status) => status === 403 || (status >= 200 && status < 300),
      });
      if (response.status === 403) {
        throw Error('403');
      }

      const rs = response.data.responses;
      return {
        profiles: rs.filter((item) => item.ref.includes('/profiles'))[0]?.body ?? [],
        segments: rs.filter((item) => item.ref.includes('/segments'))[0]?.body ?? [],
        flights: rs.filter((item) => item.ref.includes('/flights'))[0]?.body ?? [],
        participation: rs.filter((item) => item.ref.includes('/participation'))[0]?.body ?? [],
      };
    } catch (err) {
      if (err.message === '403') {
        throw Error(err.message);
      } else {
        throw Error(`API error at flightService.getAll: ${err.message}`);
      }
    }
  },
  async getById(flightId) {
    if (!flightId) {
      throw Error('No flightId passed to flightService.getById');
    }
    try {
      const response = await Vue.axios.get(`api/v1/flights/${flightId}`);
      return response.data;
    } catch (err) {
      throw Error(`API error at flightService.getById: ${err.message}`);
    }
  },
  async getResults(flightId) {
    if (!flightId) {
      throw Error('No flightId passed to flightService.getResults');
    }
    try {
      const response = await Vue.axios.get(`api/v1/flights/${flightId}/results`);
      return response.data;
    } catch (err) {
      throw Error(`API error at flightService.getResults: ${err.message}`);
    }
  },
  async createFlight(teamId, flightData) {
    if (!teamId) {
      throw Error('No teamId passed to flightService.createFlight');
    }
    try {
      const response = await Vue.axios.post(`api/v1/teams/${teamId}/flights`, {
        ...flightData,
        questions: flightData.questions.map((q) => q.text),
      });
      return response.data;
    } catch (err) {
      throw Error(`API error at flightService.createFlight: ${err.message}`);
    }
  },
  async updateFlight(flightId, patchData) {
    if (!flightId) {
      throw Error('No flightId passed to flightService.updateFlight');
    }
    if (!patchData) {
      throw Error('No patch data was passed to flightService.updateFlight');
    }
    try {
      const response = await Vue.axios.patch(
        `api/v1/flights/${flightId}`,
        patchData,
      );
      return response.data;
    } catch (err) {
      throw Error(`API error at flightService.updateFlight: ${err.message}`);
    }
  },
  async deleteOne(flightId) {
    if (!flightId) {
      throw Error('No flightId passed to flightService.deleteOne');
    }
    try {
      const response = await Vue.axios.delete(`api/v1/flights/${flightId}`);
      if (response.status !== 204) {
        throw Error('API error at flightService.deleteOne: response status not 204');
      }
    } catch (err) {
      throw Error(`API error at flightService.deleteOne: ${err.message}`);
    }
  },
  async exportCSV(flightId) {
    if (!flightId) {
      throw Error('No flightId passed to flightService.exportCSV');
    }
    try {
      const response = await Vue.axios.get(`api/v1/flights/${flightId}/export`);
      if (response.status !== 200) {
        throw Error('API error at flightService.exportCSV: response status not 200');
      }
    } catch (err) {
      throw Error(`API error at flightService.exportCSV: ${err.message}`);
    }
  },
};
