import PublicLayout from '@/layouts/PublicLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import FlightsLayout from '@/layouts/FlightsLayout.vue';
import FlightResultsPrintLayout from '@/layouts/FlightResultsPrintLayout.vue';

const profileRoutes = [
  {
    path: 'profile/change-password',
    name: 'change-password',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/ChangePasswordView.vue'),
    meta: {
      requiresCredentialedLogin: true,
      requiresNotSSOLogin: true,
    },
  },
  {
    path: 'profile/notification-preferences',
    name: 'notification-preferences',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile/NotificationPreferencesView.vue'),
  },
];

const segmentsRoute = [
  {
    path: '/segments',
    component: () => import(/* webpackChunkName: "segment" */ '../layouts/SegmentLayout.vue'),
    meta: {
      requiresStandardAccess: true,
      requiresCredentialedLogin: true,
    },
    children: [
      {
        path: 'new',
        name: 'segment-new',
        component: () => import(/* webpackChunkName: "segment" */ '../views/Segment/SegmentNewView.vue'),
        meta: {
          requiresActiveSubscription: true,
        },
      },
      {
        path: ':id?',
        name: 'segment-list',
        component: () => import(/* webpackChunkName: "segment" */ '../views/Segment/SegmentListView.vue'),
      },
    ],
  },
];

const teamRoutes = [
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team/TeamView.vue'),
    meta: {
      requiresAdminAccess: true,
      requiresCredentialedLogin: true,
    },
  },
  {
    path: '/team/members/invite',
    name: 'team-members-invite',
    component: () => import(/* webpackChunkName: "team" */ '../views/Team/TeamMembersInviteView.vue'),
    meta: {
      requiresAdminAccess: true,
      requiresCredentialedLogin: true,
      requiresActiveSubscription: true,
    },
  },
];

export const signupRoutes = [
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Signup/SignupView.vue'),
  },
];

export const publicRoutes = [{
  name: 'public',
  path: '/login',
  component: PublicLayout,
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/PasswordlessLoginView.vue'),
      props: true,
    },
    {
      path: '/manual-login',
      name: 'manualLogin',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/PasswordLoginView.vue'),
      props: true,
    },
    {
      path: '/saml-login',
      name: 'samlLogin',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/SamlLoginView.vue'),
      props: true,
    },
    {
      path: '/confirm-account/:token',
      name: 'confirm-account',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/ConfirmAccountView.vue'),
      props: true,
    },
    {
      path: '/confirm-code',
      name: 'confirm-code',
      props: {
        onSuccess: Function,
        email: String,
      },
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/ConfirmCodeView.vue'),
    },
    {
      path: '/login/:token',
      name: 'flightLinkLoginView',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/FlightLinkLoginView.vue'),
      props: true,
      meta: { bgColor: 'medium-tan' },
    },
    {
      // TODO: update URL to reset-password (requires API changes)
      path: '/set-password/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/ResetPasswordView.vue'),
      props: true,
      meta: { bgColor: 'medium-tan' },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/LogoutView.vue'),
      meta: { bgColor: 'medium-tan' },
    },
    {
      path: '/shortcode/:shortcode',
      name: 'shortcode',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/ShortcodeView.vue'),
    },
    {
      path: '/claim-account',
      name: 'claim-account',
      component: () => import(/* webpackChunkName: "public" */ '../views/Public/ConfirmAccountView.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        requiresTokenLogin: true,
      },
    },
  ],
}];

export const errorRoutes = [
  {
    path: '/error/mobile',
    name: 'error-mobile',
    component: () => import(/* webpackChunkName: "errors" */ '../views/Error/ErrorMobileView.vue'),
  },
  {
    path: '/*',
    name: 'error',
    redirect: '/error/404',
    component: PublicLayout,
    children: [
      {
        path: '/error/404',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/ErrorView.vue'),
        meta: { bgColor: 'medium-tan' },
      },
      {
        path: '/error/401',
        name: 'unauthorized',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/ErrorView.vue'),
        meta: { bgColor: 'medium-tan' },
        props: { code: '401' },
      },
      {
        path: '/error/403',
        name: 'forbiden',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/ErrorView.vue'),
        meta: { bgColor: 'medium-tan' },
        props: { code: '403' },
      },
      {
        path: '/error/403/flight',
        name: 'forbiden-flight',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/ErrorView.vue'),
        meta: { bgColor: 'medium-tan' },
        props: { code: '403', message: 'You do not have access to this flight' },
      },
    ],
  },
];

export const flightRoutes = [{
  path: '/',
  name: 'root',
  redirect: '/flights',
  component: MainLayout,
  meta: { requiresAuth: true },

  children: [
    {
      path: '/flights',
      component: FlightsLayout,
      children: [
        {
          path: '/flights',
          name: 'flights-list',
          component: () => import(/* webpackChunkName: "flight" */ '../views/Flight/FlightsListView.vue'),
        },
        {
          path: 'none',
          name: 'flights-empty',
          component: () => import(/* webpackChunkName: "flight" */ '../views/Flight/FlightsEmptyView.vue'),
        },
        {
          path: 'new',
          name: 'flight-new',
          component: () => import(/* webpackChunkName: "flight" */ '../views/Flight/FlightNewView.vue'),
          meta: {
            requiresStandardAccess: true,
            requiresCredentialedLogin: true,
            requiresActiveSubscription: true,
          },
        },
        {
          path: 'templates',
          name: 'flight-templates',
          component: () => import(/* webpackChunkName: "flight" */ '../views/FlightTemplate/FlightTemplatesView.vue'),
          meta: { requiresStandardAccess: true },
        },
        {
          path: 'templates/:slug',
          name: 'flight-template-detail',
          component: () => import(/* webpackChunkName: "flight" */ '../views/FlightTemplate/FlightTemplateDetailView.vue'),
          meta: { requiresStandardAccess: true },
        },
        {
          path: 'templates/series/:slug',
          name: 'flight-template-series',
          component: () => import(/* webpackChunkName: "flight" */ '../views/FlightTemplate/FlightTemplateSeriesView.vue'),
          meta: { requiresStandardAccess: true },
        },
        {
          path: ':id',
          name: 'flight-landing',
          component: () => import(/* webpackChunkName: "flight" */ '../layouts/FlightLayout.vue'),
        },
        {
          path: ':id/results',
          name: 'print-results',
          component: FlightResultsPrintLayout,
          meta: {
            requiresCredentialedLogin: true,
          },
        },
        {
          name: 'flight-edit',
          path: ':id/edit',
          component: () => import(/* webpackChunkName: "flight" */ '../views/Flight/FlightEditView.vue'),
          meta: { requiresFlightCreator: true },
        },
        {
          // NOTE: We only keep this route to maintain compatbility with existing email flight links
          path: ':id/q/:q?',
          name: 'flight-detail-old',
          component: () => import(/* webpackChunkName: "flight" */ '../layouts/FlightLayout.vue'),
        },
        {
          path: ':id/:s/:q?',
          name: 'flight-detail',
          component: () => import(/* webpackChunkName: "flight" */ '../views/Flight/FlightDetailView.vue'),
        },
      ],
    },

    ...segmentsRoute,
    ...teamRoutes,
    ...profileRoutes,
  ],
}];
