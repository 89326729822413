<template>
  <div class="row mt-4">
    <div class="col">
      <router-link v-if="showEmail" to="/login" :class="color">
        {{ getPasswordFreeText() }}
      </router-link>
      <router-link v-if="showManual" to="/manual-login" :class="color">
        Sign in with password
      </router-link>
    </div>
    <div class="col text-right">
      <a href="https://getballoon.com" :class="color">
        Back to getballoon.com
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BackToLoginLink',

  props: {
    color: { type: String, default: 'medium-tan' },
    loginDomainSet: { type: Boolean, default: false },
    showEmail: { type: Boolean, default: true },
    showManual: { type: Boolean, default: false },
  },
  methods: {
    getPasswordFreeText() {
      if (this.loginDomainSet) {
        return 'Forgot your password?';
      }
      return 'Sign in password-free';
    },
  },
};
</script>

<style lang="less" scoped>
a {
  &:hover { text-decoration: underline }
}
</style>
