<template>
  <div id="app" class="d-flex">
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import './assets/less/main.less';

import isMobile from '@/services/is-mobile';

export default {
  computed: {
    ...mapGetters([
      'allToasts',
    ]),
  },

  watch: {
    allToasts(arr) {
      const { message, options } = arr[arr.length - 1];
      options.fitToScreen = true;
      this.$toasted.show(message, options);
    },
  },

  created() {
    if (isMobile() && (!window.location.hash.includes('signup') && !window.location.pathname.includes('signup'))) {
      this.$router.push({ name: 'error-mobile' })
        .catch();
    }
  },
};
</script>
