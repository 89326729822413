const plugin = {
  install(Vue) {
    const requireDirective = require.context(
      '@/directives',
      false,
      /[a-z]\w+\.js$/,
    );

    requireDirective.keys().forEach((fileName) => {
      const directiveConfig = requireDirective(fileName);
      const { name, directive } = directiveConfig.default;

      Vue.directive(name, directive);
    });
  },
};

export default plugin;
