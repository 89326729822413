<template>
  <div v-if="showResults"
    id="print"
    class="position-absolute fixed-top bg-white d-flex flex-column justify-content-between w-100 p-5"> <!-- eslint-disable-line -->
    <icon width="140" height="30" name="balloon" color="navy" />
    <transition name="fade" mode="out-in">
      <LoadingBalloonAnimation v-if="isFlightLoading"/>
      <FlightResultsPrintView v-else :selected-flight="activeFlight"/>
    </transition>
    <footer class="container-fluid d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <icon width="75" height="15" name="balloon" color="navy" class="mr-4" />
        <a href="https://getballoon.com" class="navy">getballoon.com</a>
      </div>
      <div class="d-flex align-items-center">
        <icon name="facebook-logo" height="20" width="20" class="mr-4" />
        <icon name="twitter-logo" height="20" width="20" class="mr-3 mt-1" />
        <span>@balloonplatform </span>
      </div>
    </footer>
  </div>
</template>

<script>
import FlightResultsPrintView from '@/views/Flight/FlightResultsPrintView.vue';

import flighData from '@/mixins/flight-data';

export default {
  name: 'FlightResultsPrintLayout',
  mixins: [flighData],

  components: { FlightResultsPrintView },

  data() {
    return {
      // Prevent unwanted console errors when user is
      // redirected and footer icons don't finish loading.
      showResults: false,
    };
  },

  async created() {
    const flightId = this.$route.params.id;
    if (this.currentStage(flightId) !== 'postFlight') {
      this.$router.back();
      return;
    }
    await this.fetchDelayedData();
    this.showResults = true;
  },
};
</script>

<style lang="less">
#print {
  min-height: 100vh;
}

@media print {
  #print {
    padding: 0 !important;
  }
}
</style>
