const BALLOON_SESH_KEY = 'blnr-sesh';
let banners = [];

export function resetBanners() {
  banners.forEach((id) => {
    localStorage.removeItem(`banner-${id}`);
  });
  banners = [];
}

export function getBalloonSesh() {
  const blnSesh = localStorage.getItem(BALLOON_SESH_KEY);
  if (!blnSesh) {
    return undefined;
  }
  return JSON.parse(blnSesh);
}

export function setBalloonSesh(updatedSesh) {
  resetBanners();
  localStorage.setItem(BALLOON_SESH_KEY, JSON.stringify(updatedSesh));
}

export function removeBalloonSesh() {
  resetBanners();
  localStorage.removeItem(BALLOON_SESH_KEY);
}

export function getActiveTeam() {
  const blnSesh = getBalloonSesh();
  if (blnSesh && blnSesh.activeTeamId) {
    return blnSesh.activeTeamId;
  }
  return undefined;
}

export function getCredentialed() {
  const blnSesh = getBalloonSesh();
  if (blnSesh && blnSesh.credentialed) {
    return blnSesh.credentialed;
  }
  return false;
}

export function getToken() {
  const blnSesh = getBalloonSesh();
  if (blnSesh && blnSesh.token) {
    return blnSesh.token;
  }
  return '';
}

export function updateActiveTeam(activeTeamId) {
  const blnSesh = getBalloonSesh();
  setBalloonSesh({
    ...blnSesh,
    activeTeamId,
  });
}

export function getBanner(id) {
  const bannerId = `banner-${id}`;
  return localStorage.getItem(bannerId);
}

export function setBanner(banner) {
  banners.push(banner.id);

  if (getBanner(banner.id)) { return; }
  localStorage.setItem(`banner-${banner.id}`, 'visible');
}

export function closeBanner(banner) {
  localStorage.setItem(`banner-${banner.id}`, 'dismissed');
}

export function setUTMParams() {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has('utm_source')) {
    if (searchParams.has('blln_taref')) { searchParams.delete('blln_taref'); }
    localStorage.setItem('blln-utm', `?${searchParams.toString()}`);
  }
}

export function getUTMParams() {
  const utmParams = localStorage.getItem('blln-utm');
  return utmParams || '';
}

export function removeUTMParams() {
  localStorage.removeItem('blln-utm');
}

export function setTemplateAuthorReferral() {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('blln_taref')) { localStorage.setItem('blln-taref', searchParams.get('blln_taref')); }
}

export function getTemplateAuthorReferral() {
  const taReferral = localStorage.getItem('blln-taref');
  return taReferral || '';
}

export function removeTemplateAuthorReferral() {
  localStorage.removeItem('blln-taref');
}
