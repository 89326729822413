import Vue from 'vue';
import Vuex from 'vuex';
import utils from './modules/utils';
import auth from './modules/auth';
import users from './modules/users';
import teams from './modules/teams';
import flights from './modules/flights';
import balloons from './modules/balloons';
import segments from './modules/segments';
import flightTemplates from './modules/flightTemplates';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    utils,
    auth,
    users,
    teams,
    flights,
    balloons,
    segments,
    flightTemplates,
  },
});
