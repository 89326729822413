/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */
import format from 'date-fns/format';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import differenceInHours from 'date-fns/difference_in_hours';
import subWeeks from 'date-fns/sub_weeks';
import addDays from 'date-fns/add_days';
import isToday from 'date-fns/is_today';
import { normalize, schema } from 'normalizr';
import { TRACK_FLIGHT_PLAN_USED } from '@balloonplatform/client-lib/lib/js/constants/analytics-constants';
import flightService from '../../services/flight';
import {
  ADD_NEW_FLIGHT,
  UPDATE_FLIGHT,
  REMOVE_FLIGHT,
  SET_ALL_FLIGHTS,
  SET_ACTIVE_FLIGHT,
  SET_ALL_FLIGHT_PARTICIPATION,
  SET_QUESTIONS,
  SET_ACTIVE_QUESTION,
  ADD_PARTICIPATION,
  SET_BALLOON_RESULTS,
  CLEAR_ACTIVE_FLIGHT,
  NO_FLIGHT_ACCESS,
  RESET_STORE,
} from '../mutation-types';

export const STAGES = {
  PREFLIGHT: 'preFlight',
  ONE: 'one',
  TWO: 'two',
  POSTFLIGHT: 'postFlight',
};

const flightSchema = new schema.Entity('flights');
const flightListSchema = [flightSchema];
const questionSchema = new schema.Entity('questions');
const questionListSchema = [questionSchema];
const resultsSchema = new schema.Entity('balloonResults', {}, { idAttribute: 'balloonId' });
const resultsListSchema = [resultsSchema];

function initialState() {
  return {
    loadedOnce: false,
    noAccess: false,
    all: {},
    list: [],
    requiresParticipation: {},
    activeFlightId: null,
    allQuestions: {},
    questionsList: [],
    activeQuestionId: null,
  };
}
const state = initialState();

const getters = {
  allFlights: (state) => state.list.map((flightId) => state.all[flightId]),
  activeFlight: (state) => state.all[state.activeFlightId],

  preFlightList: (state, getters) => state.list
    .filter((flightId) => getters.currentStage(flightId) === STAGES.PREFLIGHT)
    .sort((a, b) => getters.secsLeftInCurrentStage(a) - getters.secsLeftInCurrentStage(b)),

  stageOneList: (state, getters) => state.list
    .filter((flightId) => getters.currentStage(flightId) === STAGES.ONE)
    .sort((a, b) => getters.secsLeftInCurrentStage(a) - getters.secsLeftInCurrentStage(b)),

  stageTwoList: (state, getters) => state.list
    .filter((flightId) => getters.currentStage(flightId) === STAGES.TWO)
    .sort((a, b) => getters.secsLeftInCurrentStage(a) - getters.secsLeftInCurrentStage(b)),

  liveList: (state, getters) => {
    const liveFlights = [
      ...getters.stageOneList,
      ...getters.stageTwoList,
    ];
    return liveFlights.sort(
      (a, b) => getters.secsLeftInCurrentStage(a) - getters.secsLeftInCurrentStage(b),
    );
  },

  completedList: (state, getters, rootState) => state.list
    .filter((flightId) => {
      const twoWeeksAgo = subWeeks(rootState.utils.now, 2);
      return (getters.currentStage(flightId) === STAGES.POSTFLIGHT)
          && (isAfter(state.all[flightId].stages.two.endDate, twoWeeksAgo));
    })
    .sort((a, b) => getters.secsLeftInCurrentStage(b) - getters.secsLeftInCurrentStage(a)),

  archivedList: (state, getters, rootState) => state.list
    .filter((flightId) => {
      const twoWeeksAgo = subWeeks(rootState.utils.now, 2);
      return (getters.currentStage(flightId) === STAGES.POSTFLIGHT)
          && !(isAfter(state.all[flightId].stages.two.endDate, twoWeeksAgo));
    }).sort((a, b) => getters.secsLeftInCurrentStage(b) - getters.secsLeftInCurrentStage(a)),

  flightNeedsParticipation: (state, getters) => (flightId) => {
    switch (getters.currentStage(flightId)) {
      case STAGES.ONE:
        return state.requiresParticipation[flightId]
          ? state.requiresParticipation[flightId][STAGES.ONE] : false;
      case STAGES.TWO:
        return state.requiresParticipation[flightId]
          ? state.requiresParticipation[flightId][STAGES.TWO] : false;
      default:
        return false;
    }
  },

  allQuestions: (state) => state.questionsList.map((qId) => state.allQuestions[qId]),
  activeQuestion: (state) => state.allQuestions[state.activeQuestionId],

  currentStage: (state, getters, rootState) => (flightId) => {
    if (!flightId) {
      return STAGES.PREFLIGHT;
    }

    const { stages } = state.all[flightId];
    const { now } = rootState.utils;
    if (isBefore(now, stages.one.startDate)) {
      return STAGES.PREFLIGHT;
    }
    if (isBefore(now, stages.two.startDate)) {
      return STAGES.ONE;
    }
    if (isBefore(now, stages.two.endDate)) {
      return STAGES.TWO;
    }
    return STAGES.POSTFLIGHT;
  },

  timeLeftInStageString: (state, getters, rootState, rootGetters) => (flightId) => {
    const { stages } = state.all[flightId];
    const { now } = rootState.utils;
    switch (getters.currentStage(flightId)) {
      case STAGES.PREFLIGHT:
        return rootGetters.timeDiffInWords(now, stages.one.startDate);
      case STAGES.ONE:
        return rootGetters.timeDiffInWords(now, stages.two.startDate);
      default:
        return rootGetters.timeDiffInWords(now, stages.two.endDate);
    }
  },

  secsLeftInCurrentStage: (state, getters, rootState) => (flightId) => {
    const { stages } = state.all[flightId];
    const { now } = rootState.utils;
    switch (getters.currentStage(flightId)) {
      case STAGES.PREFLIGHT:
        return differenceInSeconds(stages.one.startDate, now);
      case STAGES.ONE:
        return differenceInSeconds(stages.two.startDate, now);
      default:
        return differenceInSeconds(stages.two.endDate, now);
    }
  },

  timeSinceFlightEnded: (state, getters, rootState) => (flightId) => {
    const { stages } = state.all[flightId];
    const { now } = rootState.utils;
    // if flight ended less than 1 week ago, show weekday
    if (Math.abs(differenceInHours(stages.two.endDate, now)) <= 168) {
      return isToday(stages.two.endDate)
        ? 'Today'
        : format(stages.two.endDate, 'dddd');
    }
    return format(stages.two.endDate, 'MM/DD/YYYY');
  },

  flightAudienceSize: (state) => (flightId) => state.all[flightId].users.invited.userIds.length,

  stageOneParticipants: (state) => (flightId) => {
    const qs = state.all[flightId].questions ? state.all[flightId].questions.map((q) => q.id) : [];
    const set = new Set(qs.map((q) => {
      const participated = state.all[flightId].users.participated[q];
      return participated ? participated.stageOne : [];
    }).flat());
    return [...set];
  },

  stageTwoParticipants: (state) => (flightId) => {
    if (!state.all[flightId] || !state.all[flightId].questions) {
      return [];
    }

    const qs = state.all[flightId].questions.map((q) => q.id);
    const set = new Set(qs.map((q) => {
      const participated = state.all[flightId].users.participated[q];
      return participated ? participated.stageTwo : [];
    }).flat());
    return [...set];
  },

  participation: (state, getters) => (flightId) => {
    const allInvited = state.all[flightId].users.invited.userIds;
    const allParticipants = [
      ...new Set(getters.stageOneParticipants(flightId)
        .concat(getters.stageTwoParticipants(flightId))),
    ];
    const stageOne = Math.round((getters.stageOneParticipants(flightId).length
      / allInvited.length) * 100);
    const stageTwo = Math.round((getters.stageTwoParticipants(flightId).length
      / allInvited.length) * 100);
    const overall = Math.round((allParticipants.length / allInvited.length) * 100);
    return { stageOne, stageTwo, overall };
  },

  formatISOString: (state, getters, rootState) => (ISOString) => {
    const { now } = rootState.utils;
    // if less than 1 week, add time to date
    if (Math.abs(differenceInHours(ISOString, now)) <= 168) {
      return format(ISOString, 'ddd. [at] h:mma');
    }
    return format(ISOString, 'MMM. D, YYYY');
  },

  isQuickFlight: (state, getters, rootState) => (flightId) => {
    const { stages } = state.all[flightId];
    const inTwoDays = addDays(rootState.utils.now, 2);
    const shortFlight = Math.abs(
      differenceInSeconds(stages.one.startDate, stages.two.endDate),
    ) <= 3600;

    return shortFlight
      && getters.currentStage(flightId) === 'preFlight'
      && isAfter(inTwoDays, state.all[flightId].stages.one.startDate);
  },

  isEndingFlight: (state, getters, rootState) => (flightId) => {
    const { stages } = state.all[flightId];
    const { now } = rootState.utils;
    // if less than 6 hours to end
    return getters.currentStage(flightId) !== 'postFlight'
      && Math.abs(differenceInHours(stages.two.endDate, now)) <= 6;
  },

  isMyFlight: (state, getters, rootState) => (flightId) => state.all[flightId]
    && state.all[flightId].createdUserId === rootState.users.loggedInUser.id,

  canAccessResults: (state, getters) => (flightId) => {
    if (getters.currentStage(flightId) !== 'postFlight') {
      return true;
    }

    return state.all[flightId].hasPrivateResults ? getters.isMyFlight(flightId) : true;
  },

  canAccessPreflight: (state, getters) => (flightId) => {
    if (getters.currentStage(flightId) !== 'preFlight') {
      return true;
    }

    return getters.isMyFlight(flightId);
  },

  totalFlightPumps: (state, getters, rootState) => {
    const pumps = rootState.balloons.list.map((balloonId) => getters.allPumpsForBalloon(balloonId));
    return pumps.reduce((acc, cur) => acc + cur, 0);
  },

  questionNumber: (state) => (questionId) => state.questionsList.indexOf(questionId) + 1,
};

const actions = {
  setActiveFlight({ commit, dispatch }, flightId) {
    if (state.list.includes(flightId)) {
      commit(SET_ACTIVE_FLIGHT, flightId);
      dispatch('setQuestions');
    } else {
      throw Error('Flight not found.');
    }
  },

  setQuestions({ commit }) {
    if (state.list.includes(state.activeFlightId)) {
      const normalized = state.all[state.activeFlightId]?.questions
        ? normalize(state.all[state.activeFlightId].questions, questionListSchema)
        : { entities: { questions: [] } };

      commit(SET_QUESTIONS, {
        all: normalized.entities.questions,
        list: normalized.result,
      });
    } else {
      throw Error('Flight not found.');
    }
  },

  clearActiveFlight({ commit }) {
    commit(CLEAR_ACTIVE_FLIGHT);
  },

  setActiveQuestion({ commit }, qId) {
    commit(SET_ACTIVE_QUESTION, qId);
  },

  async setFlights({ commit, dispatch, rootState }) {
    try {
      const flightsData = await flightService.getAll(rootState.teams.activeTeamId);
      const normalized = normalize(flightsData.flights, flightListSchema);
      commit(SET_ALL_FLIGHTS, {
        all: normalized.entities.flights || {},
        list: normalized.result,
      });
      commit(SET_ALL_FLIGHT_PARTICIPATION, flightsData.participation);
      await Promise.all([
        dispatch('setAllSegments', flightsData.segments),
        dispatch('setProfilesOnTeam', flightsData.profiles),
      ]);
    } catch (err) {
      if (err.message === '403') {
        commit(NO_FLIGHT_ACCESS);
      } else {
        dispatch('logErrorToFS', err.message);
        dispatch('errorToast', 'There was a problem fetching your flights :(');
      }
    }
  },

  async getActiveFlight({ commit, dispatch, state }) {
    try {
      const flightData = await flightService.getById(state.activeFlightId);
      commit(UPDATE_FLIGHT, flightData);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem fetching the flight data :(');
    }
  },

  async getFlightResults({ commit, dispatch, state }) {
    try {
      const flightResults = await flightService.getResults(state.activeFlightId);
      const normalized = normalize(flightResults.results, resultsListSchema);
      const balloonResults = normalized.entities.balloonResults || {};
      commit(SET_BALLOON_RESULTS, balloonResults);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem fetching the flight results :(');
    }
  },

  async createFlight({
    commit, dispatch, rootState, rootGetters,
  }, flightData) {
    try {
      const createdFlight = await flightService
        .createFlight(rootState.teams.activeTeamId, flightData);
      if (flightData.emails.length > 0) {
        await dispatch('setProfilesOnTeam');
      }
      commit(ADD_NEW_FLIGHT, createdFlight);
      if (rootState.flightTemplates.activeTemplateId) {
        window.analytics.track(TRACK_FLIGHT_PLAN_USED, {
          flightId: createdFlight.id,
          templateId: rootGetters.activeFlightTemplate.id,
          templateName: rootGetters.activeFlightTemplate.name,
        }, { groupId: createdFlight.teamId });
        dispatch('clearActiveFlightTemplate');
      }
      return createdFlight;
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem creating the flight');
      throw Error('There was a problem creating the flight');
    }
  },

  async updateFlight({
    commit, dispatch,
  }, [flightId, patchData]) {
    try {
      const updatedFlight = await flightService.updateFlight(flightId, patchData);
      commit(UPDATE_FLIGHT, updatedFlight);

      if (patchData.users
          && patchData.users.invited
          && patchData.users.invited.emails) {
        if (patchData.users.invited.emails.length > 0) {
          await dispatch('setProfilesOnTeam');
        }
      }

      if (updatedFlight.id === state.activeFlightId) {
        const normalized = normalize(state.all[state.activeFlightId].questions, questionListSchema);
        commit(SET_QUESTIONS, {
          all: normalized.entities.questions,
          list: normalized.result,
        });
      }

      return updatedFlight;
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem updating the flight');
      throw Error('There was a problem updating the flight');
    }
  },

  addParticipation({
    state, rootState, commit, getters,
  }) {
    if (getters.flightNeedsParticipation(state.activeFlightId)) {
      const stage = getters.currentStage(state.activeFlightId);
      let altStageString = 'stageOne';
      if (stage === 'two') {
        altStageString = 'stageTwo';
      }
      commit(ADD_PARTICIPATION, {
        flightId: state.activeFlightId,
        userId: rootState.users.loggedInUser.id,
        questionId: state.activeQuestionId,
        stage,
        altStageString,
      });
    }
  },

  async deleteFlight({ commit, dispatch }, flightId) {
    try {
      await flightService.deleteOne(flightId);
      commit(REMOVE_FLIGHT, flightId);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
    }
  },

  async exportCSV({ dispatch, state }) {
    try {
      await flightService.exportCSV(state.activeFlightId);
      dispatch('successToast', 'The flight data export has been emailed to you!');
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem exporting your flight data');
    }
  },
};

// mutations (modify state)
const mutations = {
  [ADD_NEW_FLIGHT](state, newFlight) {
    state.all = { ...state.all, ...{ [newFlight.id]: newFlight } };
    state.list.push(newFlight.id);
    state.requiresParticipation[newFlight.id] = { one: true, two: true };
  },
  [UPDATE_FLIGHT](state, updatedFlight) {
    state.all[updatedFlight.id] = { ...updatedFlight };
  },
  [REMOVE_FLIGHT](state, flightId) {
    delete state.all[flightId];
    state.list = state.list.filter((id) => id !== flightId);
  },
  [SET_ALL_FLIGHTS](state, flightData) {
    state.all = flightData.all;
    state.list = flightData.list;
    state.loadedOnce = true;
  },
  [NO_FLIGHT_ACCESS](state) {
    state.noAccess = true;
  },
  [SET_ACTIVE_FLIGHT](state, flightId) {
    state.activeFlightId = flightId;
  },
  [SET_ALL_FLIGHT_PARTICIPATION](state, participationData) {
    state.list.forEach((flightId) => {
      state.requiresParticipation[flightId] = { one: false, two: false };
    });
    state.requiresParticipation = participationData;
  },
  [SET_QUESTIONS](state, qData) {
    state.allQuestions = qData.all;
    state.questionsList = qData.list;

    if (state.questionsList && !state.questionsList.includes(state.activeQuestionId)) {
      const [qId] = qData.list;
      state.activeQuestionId = qId;
    }
  },
  [SET_ACTIVE_QUESTION](state, qId) {
    state.activeQuestionId = qId;
  },
  [ADD_PARTICIPATION](state, data) {
    state.requiresParticipation[data.flightId][data.stage] = false;
    state.all[data.flightId].users.participated[data.questionId][data.altStageString]
      .push(data.userId);
  },
  [CLEAR_ACTIVE_FLIGHT](state) {
    state.activeFlightId = null;
    state.allQuestions = {};
    state.questionsList = [];
    state.activeQuestionId = null;
  },
  [RESET_STORE](state) {
    state.loadedOnce = false;
    state.noAccess = false;
    state.all = {};
    state.list = [];
    state.requiresParticipation = {};
    state.activeFlightId = null;
    state.allQuestions = {};
    state.questionsList = [];
    state.activeQuestionId = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
