<template>
  <div v-if="visible && !isDismissed"
    class="row d-flex align-content-center banner"
    :class="{ 'error-banner': type === 'error', 'success-banner': type === 'success' }"
  >
    <h4 class="align-self-center ml-auto">
      {{ message }}

      <router-link v-if="goTo" class="underline" :to="goTo">
        See Details
      </router-link>
    </h4>
    <button class="banner-close ml-auto mr-5" @click="close">
      <img src="/images/icons/x.svg" width="11" class="hover-scale" alt="Close banner" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as localStorageUtil from '../../utils/localStorage';

export default {
  name: 'Banner',

  data() {
    return {
      type: '',
      message: '',
      visible: false,
      dismissible: false,
    };
  },

  props: {
    id: { type: String, required: true },
  },

  computed: {
    ...mapGetters(['bannerById']),

    goTo() { return this.bannerById(this.id).to; },

    isDismissed() {
      return this.dismissible && localStorageUtil.getBanner(this.id) === 'dismissed';
    },
  },

  created() {
    this.initBanner();
  },

  methods: {
    initBanner() {
      const banner = this.bannerById(this.id);
      this.type = banner.type;
      this.message = banner.message;
      this.dismissible = banner.dismissible;
      this.visible = true;
    },

    close() {
      this.visible = false;
      this.$store.dispatch('closeBanner', this.id);
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  min-height: 4.4rem;
  background: @pink;
}

.banner-close img {
  filter:
    brightness(0)
    saturate(100%)
    invert(15%)
    sepia(40%)
    saturate(786%)
    hue-rotate(200deg)
    brightness(90%)
    contrast(104%);
}

a {
  color: @navy;
  font-size: inherit;
  font-weight: 300;
}

.success-banner {
  background-color: @teal;
}

.error-banner {
  background: @orange;

h4, a {
    color: white;
  }

  img {
    filter:
      brightness(0)
      saturate(100%)
      invert()
  }
}
</style>
