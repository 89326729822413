<template>
 <button
    :class="{ open: showComments }"
    data-tour="toggle-comments"
    @click="toggleComments">
      <span>{{ commentsCount || '' }}</span>
      <icon name="comment"/>
  </button>
</template>

<script>
export default {
  name: 'BalloonCommentToggle',

  props: {
    commentsCount: { type: Number, default: 0 },
    showComments: { type: Boolean, default: false },
  },

  methods: {
    toggleComments() {
      this.$emit('toggle-comments');
    },
  },
};
</script>

<style lang="less" scoped>
button {
    color: @navy;
    font-weight: 900;
    letter-spacing: 0;
    span {
      margin-right: 0.4rem;
    }

    ::v-deep svg {
      margin-top: -2px;
      path {
        stroke: @navy;
        fill: none;
      }
    }

    span {
      font-size: 1rem;
    }

    &.open {
      color: @teal;

      ::v-deep svg {
        path {
          stroke: @teal;
        }
      }
    }
  }
</style>
