<template>
  <div
    class="container-fluid d-flex align-items-center justify-content-center"
    :style="{height: `${fullScreen ? '100vh' : '100%'} !important`}">
    <div class="text-center">
      <svg
        :width="width"
        :height="height"
        viewBox="0 0 72 87"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <mask id="mask1">
            <circle cx="35" :cy="100" :r="100" stroke="none" fill="white" />
          </mask>
          <mask id="mask2">
            <circle id="c1" cx="35" cy="100" :r="0" stroke="none" fill="white">
              <animate
                id="a1"
                attributeName="r"
                begin="0s;a4.end"
                dur="5s"
                from="0"
                to="100"
              />
            </circle>
          </mask>
          <mask id="mask3">
            <circle id="c2" cx="35" cy="100" :r="0" stroke="none" fill="white">
              <animate
                id="a2"
                attributeName="r"
                begin="a1.end"
                dur="5s"
                from="0"
                to="100"
              />
            </circle>
          </mask>
          <mask id="mask4">
            <circle id="c3" cx="35" cy="100" :r="0" stroke="none" fill="white">
              <animate
                id="a3"
                attributeName="r"
                begin="a2.end"
                dur="5s"
                from="0"
                to="100"
              />
            </circle>
          </mask>
          <mask id="mask5">
            <circle id="c3" cx="35" cy="100" :r="0" stroke="none" fill="white">
              <animate
                id="a4"
                attributeName="r"
                begin="a3.end"
                dur="5s"
                from="0"
                to="100"
              />
            </circle>
          </mask>
        </defs>
        <g
          id="balloon_mark_green"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="nonzero"
        >
          <!-- eslint-disable-next-line -->
          <path d="M36.8165395,76.125 L46.4606365,84.7318652 C46.9845671,85.1994463 47.040886,86.0154908 46.5864285,86.5545514 C46.3479032,86.8374814 46.0017915,87 45.6377681,87 L26.4783119,87 C25.7847451,87 25.222498,86.4215163 25.222498,85.7079208 C25.222498,85.3009804 25.408829,84.9178187 25.7253737,84.6738328 L36.8165395,76.125 Z M36,0 C57.2442587,0 73.1557823,17.6452552 71.9341236,38.8609511 C71.1359086,52.7229951 59.6063985,66.4087632 37.615283,82.46875 C36.1814083,78.9055468 35.574217,77.0423478 35.7937092,76.8791532 C55.6306928,62.1301576 65.7537483,50.0084094 66.4144804,38.5339352 C67.452924,20.5000113 54.0244179,5.6083251 36,5.6083251 C17.9755821,5.6083251 4.54707606,20.5000113 5.58551951,38.5339352 C6.17752984,48.8149661 13.2629924,57.1585812 29.3003732,70.1327971 L24.4888495,73.4017222 C8.22795354,59.9521428 0.750752212,50.7547122 0.065876399,38.8609511 C-1.15578233,17.6452552 14.7557413,0 36,0 Z M36.6625767,11.78125 C49.9481509,11.78125 60.5153374,22.6773066 60.5153374,35.796875 L54.9681837,35.796875 C54.9681837,25.7324228 46.8545511,17.3662791 36.6625767,17.3662791 L36.6625767,11.78125 Z"
            id="balloon-mark"
            fill="#154f4a"
            mask="url(#mask1)"
          >
            <animate
              id="a4"
              attributeName="fill"
              values="#e3b8ae"
              begin="a1.end"
              dur="0s"
            />
            <animate
              id="a5"
              attributeName="fill"
              values="#1d1d44"
              begin="a2.end"
              dur="0s"
            />
            <animate
              id="a6"
              attributeName="fill"
              values="#9dcbc2"
              begin="a3.end"
              dur="0s"
            />
            <animate
              id="a6"
              attributeName="fill"
              values="#154f4a"
              begin="a4.end"
              dur="0s"
            />
          </path>
        </g>
        <g
          id="balloon_mark_pink"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="nonzero"
        >
          <!-- eslint-disable-next-line -->
          <path d="M36.8165395,76.125 L46.4606365,84.7318652 C46.9845671,85.1994463 47.040886,86.0154908 46.5864285,86.5545514 C46.3479032,86.8374814 46.0017915,87 45.6377681,87 L26.4783119,87 C25.7847451,87 25.222498,86.4215163 25.222498,85.7079208 C25.222498,85.3009804 25.408829,84.9178187 25.7253737,84.6738328 L36.8165395,76.125 Z M36,0 C57.2442587,0 73.1557823,17.6452552 71.9341236,38.8609511 C71.1359086,52.7229951 59.6063985,66.4087632 37.615283,82.46875 C36.1814083,78.9055468 35.574217,77.0423478 35.7937092,76.8791532 C55.6306928,62.1301576 65.7537483,50.0084094 66.4144804,38.5339352 C67.452924,20.5000113 54.0244179,5.6083251 36,5.6083251 C17.9755821,5.6083251 4.54707606,20.5000113 5.58551951,38.5339352 C6.17752984,48.8149661 13.2629924,57.1585812 29.3003732,70.1327971 L24.4888495,73.4017222 C8.22795354,59.9521428 0.750752212,50.7547122 0.065876399,38.8609511 C-1.15578233,17.6452552 14.7557413,0 36,0 Z M36.6625767,11.78125 C49.9481509,11.78125 60.5153374,22.6773066 60.5153374,35.796875 L54.9681837,35.796875 C54.9681837,25.7324228 46.8545511,17.3662791 36.6625767,17.3662791 L36.6625767,11.78125 Z"
            id="balloon-mark-blue"
            fill="#e3b8ae"
            mask="url(#mask2)"
          ></path>
        </g>
        <g
          id="balloon_mark_navy"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="nonzero"
        >
          <!-- eslint-disable-next-line -->
          <path d="M36.8165395,76.125 L46.4606365,84.7318652 C46.9845671,85.1994463 47.040886,86.0154908 46.5864285,86.5545514 C46.3479032,86.8374814 46.0017915,87 45.6377681,87 L26.4783119,87 C25.7847451,87 25.222498,86.4215163 25.222498,85.7079208 C25.222498,85.3009804 25.408829,84.9178187 25.7253737,84.6738328 L36.8165395,76.125 Z M36,0 C57.2442587,0 73.1557823,17.6452552 71.9341236,38.8609511 C71.1359086,52.7229951 59.6063985,66.4087632 37.615283,82.46875 C36.1814083,78.9055468 35.574217,77.0423478 35.7937092,76.8791532 C55.6306928,62.1301576 65.7537483,50.0084094 66.4144804,38.5339352 C67.452924,20.5000113 54.0244179,5.6083251 36,5.6083251 C17.9755821,5.6083251 4.54707606,20.5000113 5.58551951,38.5339352 C6.17752984,48.8149661 13.2629924,57.1585812 29.3003732,70.1327971 L24.4888495,73.4017222 C8.22795354,59.9521428 0.750752212,50.7547122 0.065876399,38.8609511 C-1.15578233,17.6452552 14.7557413,0 36,0 Z M36.6625767,11.78125 C49.9481509,11.78125 60.5153374,22.6773066 60.5153374,35.796875 L54.9681837,35.796875 C54.9681837,25.7324228 46.8545511,17.3662791 36.6625767,17.3662791 L36.6625767,11.78125 Z"
            id="balloon-mark-yellow"
            fill="#1d1d44"
            mask="url(#mask3)"
          ></path>
        </g>
        <g
          id="balloon_mark_teal"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="nonzero"
        >
          <!-- eslint-disable-next-line -->
          <path d="M36.8165395,76.125 L46.4606365,84.7318652 C46.9845671,85.1994463 47.040886,86.0154908 46.5864285,86.5545514 C46.3479032,86.8374814 46.0017915,87 45.6377681,87 L26.4783119,87 C25.7847451,87 25.222498,86.4215163 25.222498,85.7079208 C25.222498,85.3009804 25.408829,84.9178187 25.7253737,84.6738328 L36.8165395,76.125 Z M36,0 C57.2442587,0 73.1557823,17.6452552 71.9341236,38.8609511 C71.1359086,52.7229951 59.6063985,66.4087632 37.615283,82.46875 C36.1814083,78.9055468 35.574217,77.0423478 35.7937092,76.8791532 C55.6306928,62.1301576 65.7537483,50.0084094 66.4144804,38.5339352 C67.452924,20.5000113 54.0244179,5.6083251 36,5.6083251 C17.9755821,5.6083251 4.54707606,20.5000113 5.58551951,38.5339352 C6.17752984,48.8149661 13.2629924,57.1585812 29.3003732,70.1327971 L24.4888495,73.4017222 C8.22795354,59.9521428 0.750752212,50.7547122 0.065876399,38.8609511 C-1.15578233,17.6452552 14.7557413,0 36,0 Z M36.6625767,11.78125 C49.9481509,11.78125 60.5153374,22.6773066 60.5153374,35.796875 L54.9681837,35.796875 C54.9681837,25.7324228 46.8545511,17.3662791 36.6625767,17.3662791 L36.6625767,11.78125 Z"
            id="balloon-mark-yellow"
            fill="#9dcbc2"
            mask="url(#mask4)"
          ></path>
        </g>
        <g
          id="balloon_mark_green2"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="nonzero"
        >
          <!-- eslint-disable-next-line -->
          <path d="M36.8165395,76.125 L46.4606365,84.7318652 C46.9845671,85.1994463 47.040886,86.0154908 46.5864285,86.5545514 C46.3479032,86.8374814 46.0017915,87 45.6377681,87 L26.4783119,87 C25.7847451,87 25.222498,86.4215163 25.222498,85.7079208 C25.222498,85.3009804 25.408829,84.9178187 25.7253737,84.6738328 L36.8165395,76.125 Z M36,0 C57.2442587,0 73.1557823,17.6452552 71.9341236,38.8609511 C71.1359086,52.7229951 59.6063985,66.4087632 37.615283,82.46875 C36.1814083,78.9055468 35.574217,77.0423478 35.7937092,76.8791532 C55.6306928,62.1301576 65.7537483,50.0084094 66.4144804,38.5339352 C67.452924,20.5000113 54.0244179,5.6083251 36,5.6083251 C17.9755821,5.6083251 4.54707606,20.5000113 5.58551951,38.5339352 C6.17752984,48.8149661 13.2629924,57.1585812 29.3003732,70.1327971 L24.4888495,73.4017222 C8.22795354,59.9521428 0.750752212,50.7547122 0.065876399,38.8609511 C-1.15578233,17.6452552 14.7557413,0 36,0 Z M36.6625767,11.78125 C49.9481509,11.78125 60.5153374,22.6773066 60.5153374,35.796875 L54.9681837,35.796875 C54.9681837,25.7324228 46.8545511,17.3662791 36.6625767,17.3662791 L36.6625767,11.78125 Z"
            id="balloon-mark-yellow"
            fill="#154f4a"
            mask="url(#mask5)"
          ></path>
        </g>
      </svg>
      <h2 :class="textColor">{{ text }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingBalloonAnimation',
  props: {
    width: { type: String, default: '207' },
    height: { type: String, default: '250' },
    text: { type: String, default: 'Loading...' },
    textColor: { type: String, default: 'navy' },
    fullScreen: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  svg {
    display: none;
  }
}
</style>
