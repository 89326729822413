<template>
  <section class="row" aria-live="polite" aria-relevant="additions" aria-atomic="false">
    <div class="col">

      <div class="row">
        <div class="col pl-0">
          <h5>Top {{Math.min(5, balloonCount)}} Balloons</h5>
        </div>
      </div>

      <transition-group tag="div" name="list">
        <Balloon
          v-for="(balloon,idx) in sortedBalloons.slice(0, 5)"
          :key="balloon.id"
          :balloonIndex="idx"
          :balloon="balloon"
          :presentation-mode="presentationMode"
          :print-mode="printMode"/>
      </transition-group>

      <div class="row mt-5" v-if="balloonCount > 5">
        <div class="col pl-0">
          <h5>More Balloons</h5>
        </div>
      </div>

      <transition-group tag="div" name="list">
        <Balloon
          v-for="(balloon,idx) in sortedBalloons.slice(5)"
          :key="balloon.id"
          :balloonIndex="idx+5"
          :balloon="balloon"
          :presentation-mode="presentationMode"
          :print-mode="printMode"/>
      </transition-group>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Balloon from '@/components/Balloon/Balloon.vue';

export default {
  name: 'PostFlightBalloonList',

  components: {
    Balloon,
  },

  props: {
    balloons: Array,
    presentationMode: { type: Boolean, default: false },
    printMode: { type: Boolean, default: false },
  },

  computed: {
    ...mapGetters([
      'activeQuestion',
      'balloonResults',
      'balloonsForQ',
      'totalPumpsForQ',
    ]),

    pumpCount() {
      return this.totalPumpsForQ(this.activeQuestion.id);
    },

    balloonCount() {
      return this.balloons.length;
    },

    sortedBalloons() {
      return [...this.balloons].sort((a, b) => this.balloonResults(b.id)
        .balloonrScore - this.balloonResults(a.id).balloonrScore);
    },
  },
};
</script>

<style lang="less" scoped>
h5 {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: @green;
  margin-bottom: 2.4rem;
}
</style>
