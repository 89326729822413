/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */
import { TRACK_SIGN_OUT } from '@balloonplatform/client-lib/lib/js/constants/analytics-constants';
import * as localStorageUtil from '@/utils/localStorage';
import * as userToken from '@/utils/userToken';
import authService from '../../services/auth';
import {
  LOGIN,
  LOGIN_SUCCESS,
  SET_USER,
  RESET_STORE,
  SET_SHORTCODE_DATA,
} from '../mutation-types';

const state = {
  pending: false,
  token: userToken.get(),
  tokenExpiration: userToken.getExpirationDate(),
  credentialed: userToken.isCredentialed(),
  shortcodeData: null,
};

// getters
const getters = {
  isLoggedIn: (state, getters) => !!state.token && !getters.isTokenExpired,
  isPending: (state) => state.pending,
  isCredentialed: (state) => state.credentialed,
  shortcodeData: (state) => state.shortcodeData,
  isTokenExpired: (state, getters, rootState) => {
    if (state.tokenExpiration > rootState.utils.now) {
      return false;
    }
    localStorageUtil.removeBalloonSesh();
    userToken.removeCookie();
    return true;
  },
};

// actions (async, commit mutations)
const actions = {
  async login({ dispatch, commit }, creds) {
    commit(LOGIN);
    try {
      const data = await authService.login(creds);
      const authData = data.user;

      commit(LOGIN_SUCCESS, { token: data.token, credentialed: true });
      commit(SET_USER, { ...authData });

      await dispatch('setAllTeams', data.teams);
      await dispatch('setActiveTeam');

      const userHash = authData.integrations.Intercom
        ? authData.integrations.Intercom.user_hash
        : '';
      window.analytics.identify(
        authData.id,
        {
          email: authData.email,
        },
        {
          Intercom: {
            hideDefaultLauncher: true,
            user_hash: userHash,
          },
        },
      );
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('logout');
      throw Error('Please check your email and password, and try again.');
    }
  },
  async samlLogin({ dispatch }, queryParams) {
    try {
      await authService.samlLogin(queryParams);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('logout');
      throw Error('Please check your email, and try again.');
    }
  },
  logout({ commit, rootState }) {
    try {
      // capture temp data, so user can be immediately logged out
      const tempToken = state.token;
      const tempTeamId = rootState.teams.activeTeamId;
      commit(RESET_STORE);
      authService.logout(tempToken);
      window.analytics.track(
        TRACK_SIGN_OUT,
        {},
        {
          groupId: tempTeamId,
        },
      );
      window.analytics.reset();
    } catch (err) {
      commit(RESET_STORE);
      throw Error(err.message);
    }
  },
  async changePassword({ dispatch, commit, rootState }, data) {
    try {
      const response = await authService.changePassword(data);
      commit(LOGIN_SUCCESS, { token: response.token, credentialed: true });
      if (!rootState.users.loggedInUser) {
        await dispatch('initUser');
      }
      dispatch('successToast', 'Your password has been updated!');
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem setting your password. Please try again.',
      );
    }
  },
  async tokenCheckPassword({ dispatch }, token) {
    const scope = 'password-reset';
    try {
      const data = {
        token,
        scope,
      };
      await authService.tokenCheck(data);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('Your password reset is no longer valid. Request a new one!');
    }
  },
  async tokenCheckSignup({ dispatch }, token) {
    const scope = 'signup-completion';
    try {
      const data = {
        token,
        scope,
      };
      await authService.tokenCheck(data);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('Your invite is no longer valid. Request a new one!');
    }
  },
  async requestPassword({ dispatch }, data) {
    try {
      await authService.requestPassword(data);
      dispatch(
        'successToast',
        'Password reset requested. Please check your email.',
      );
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem requesting a new password. Please double-check the email address and try again.',
      );
    }
  },
  async loginLink({ dispatch }, data) {
    try {
      await authService.loginLink(data);
      dispatch(
        'successToast',
        'User login link requested. Please check your email.',
      );
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem requesting user login link. Please double-check the email address and try again.',
      );
    }
  },
  async completeAccountConfirmation({ dispatch, commit }, data) {
    commit(LOGIN);
    try {
      const authData = await authService.completeAccountConfirmation(data);
      commit(LOGIN_SUCCESS, { token: authData.token, credentialed: true });
      commit(SET_USER, { ...authData.user });
      await Promise.all([dispatch('setAllTeams'), dispatch('setAllSegments')]);
      await dispatch('setActiveTeam');
      await dispatch('setProfilesOnTeam');
      const userHash = authData.user.integrations.Intercom
        ? authData.user.integrations.Intercom.user_hash
        : '';
      window.analytics.identify(
        authData.user.id,
        {
          email: authData.user.email,
        },
        {
          Intercom: {
            hideDefaultLauncher: true,
            user_hash: userHash,
          },
        },
      );
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('There was a problem completing your account confirmation.');
    }
  },

  async completeAccountClaim({ dispatch, commit }, data) {
    commit(LOGIN);
    try {
      const authData = await authService.completeAccountClaim(data);
      commit(LOGIN_SUCCESS, { token: authData.token, credentialed: true });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('There was a problem claiming your account.');
    }
  },

  async completeSignup({ dispatch, commit }, authData) {
    commit(LOGIN);
    try {
      commit(LOGIN_SUCCESS, { token: authData.token, credentialed: true });
      commit(SET_USER, { ...authData.user });
      await Promise.all([dispatch('setAllTeams'), dispatch('setAllSegments')]);
      await dispatch('setActiveTeam');
      await dispatch('setProfilesOnTeam');

      const userHash = authData.user.integrations.Intercom
        ? authData.user.integrations.Intercom.user_hash
        : '';
      window.analytics.identify(
        authData.user.id,
        {
          email: authData.user.email,
        },
        {
          Intercom: {
            hideDefaultLauncher: true,
            user_hash: userHash,
          },
        },
      );

      localStorageUtil.removeUTMParams();
      localStorageUtil.removeTemplateAuthorReferral();
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('There was a problem completing your signup.');
    }
  },

  async validateShortcode({ dispatch, commit }, data) {
    try {
      const response = await authService.validateShortcode(data);
      commit(SET_SHORTCODE_DATA, response.data);

      if (response.data.validateCode) {
        dispatch(
          'successToast',
          `A ${data.resend ? 'new' : ''} verification code has been sent to ${
            data.email
          }`,
        );
        return;
      }

      if (response.data.loginRequired) {
        dispatch(
          'successToast',
          'You already have a Balloon account, please log in!',
        );
      }
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem validating your request. Please double-check the email address and try again.',
      );
    }
  },

  async completeShortcode({ dispatch, commit }, data) {
    commit(LOGIN);
    try {
      const request = { email: data.email, shortcode: data.shortcode };
      const {
        user,
        flightId,
        token,
        teamId,
        credentialed,
      } = await authService.completeShortcode(request);

      if (token) {
        commit(LOGIN_SUCCESS, { token, credentialed });
      }
      if (user) {
        commit(SET_USER, { ...user });
      }
      await Promise.all([dispatch('setAllTeams'), dispatch('setAllSegments')]);
      await dispatch('setActiveTeam', teamId);
      await dispatch('setProfilesOnTeam');
      const userHash = user.integrations.Intercom
        ? user.integrations.Intercom.user_hash
        : '';
      window.analytics.identify(
        user.id,
        { email: user.email },
        {
          Intercom: {
            hideDefaultLauncher: true,
            user_hash: userHash,
          },
        },
      );

      dispatch('successToast', 'Success! Accessing your flight now!');
      return flightId;
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('There was a problem validating your request.');
    }
  },
  async validateCode({ dispatch, commit }, data) {
    commit(LOGIN);
    try {
      const { token } = await authService.validateCode(data);
      commit(LOGIN_SUCCESS, { token, credentialed: true });
      dispatch('successToast', 'Code Confirmed!');
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem validating your request. Please double-check your code and try again!',
      );
    }
  },
  async setShortcodeData({ commit }, data) {
    commit(SET_SHORTCODE_DATA, data);
  },
  async checkTeamDomain({ dispatch }, teamDomain) {
    try {
      return await authService.checkTeamDomain(teamDomain);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem validating your team domain',
      );
    }
  },
};

// mutations
const mutations = {
  [LOGIN](state) {
    state.pending = true;
  },
  [LOGIN_SUCCESS](state, credentialData) {
    localStorageUtil.setBalloonSesh({
      token: credentialData.token,
      credentialed: credentialData.credentialed,
      activeTeamId: localStorageUtil.getActiveTeam(),
    });
    state.token = credentialData.token;
    state.tokenExpiration = userToken.getExpirationDate();
    state.credentialed = credentialData.credentialed;
    state.pending = false;
  },
  [RESET_STORE](state) {
    state.token = '';
    state.tokenExpiration = null;
    state.pending = false;
    state.credentialed = false;
    state.shortcodeData = null;
    localStorageUtil.removeBalloonSesh();
    userToken.removeCookie();
  },
  [SET_SHORTCODE_DATA](state, data) {
    state.shortcodeData = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
