/* eslint no-shadow: ["error", { "allow": ["state","getters"] }] */

import { normalize, schema } from 'normalizr';
import sentry from '@/services/sentry';

import userService from '../../services/user';
import {
  SET_USER,
  SET_ALL_USERS,
  SET_ALL_PROFILES,
  SET_TEAM_SUBSCRIPTION,
  UPDATE_USER,
  RESET_STORE,
} from '../mutation-types';

// Define a users schema
const userSchema = new schema.Entity('users');
const userListSchema = [userSchema];

// Define a profiles schema
const profileSchema = new schema.Entity(
  'profiles',
  {},
  { idAttribute: 'userId' },
);
const profileListSchema = [profileSchema];

// initial state
const state = {
  loggedInUser: null,
  userList: [],
  allUsers: {},
  profileList: [],
  allProfiles: {},
};

// getters
const getters = {
  loggedInUser: (state) => state.loggedInUser,
  allUsers: (state, getters, rootState) => state.userList.map((userId) => {
    if (userId && state.allUsers[userId]) {
      const teamData = state.allUsers[userId].teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return {
          ...state.allUsers[userId],
          teamRole: teamData.role,
          teamStatus: teamData.status,
          teamAuthentication: teamData.authenticationMethod,
          flightParticipantLimit: teamData.plan.flightParticipantLimit,
          planId: teamData.plan.id,
        };
      }
    }
    return {};
  }),
  roleOnActiveTeam: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isLoggedIn && rootState.teams.activeTeamId) {
      const teamData = state.loggedInUser.teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return teamData.role;
      }
    }
    return false;
  },
  participantLimitOnActiveTeam: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isLoggedIn && rootState.teams.activeTeamId) {
      const teamData = state.loggedInUser.teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return teamData.plan.flightParticipantLimit;
      }
    }
    return false;
  },
  planIdOnActiveTeam: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isLoggedIn && rootState.teams.activeTeamId) {
      const teamData = state.loggedInUser.teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return teamData.plan.id;
      }
    }
    return false;
  },
  allProfiles: (state) => state.profileList.map((userId) => state.allProfiles[userId]),
  profileById: (state) => (userId) => state.allProfiles[userId] || {},
  previousStatusById: (state, getters, rootState) => (userId) => state.allUsers[userId].teams.find(
    (team) => team.teamId === rootState.teams.activeTeamId,
  ).previousStatus || 'none',
  userNameById: (state) => (userId) => {
    const profile = state.allProfiles[userId];
    if (!profile) {
      return null;
    }
    const { firstName, lastName, email } = profile;
    return firstName.length || lastName.length
      ? `${firstName} ${lastName}`.trim()
      : email;
  },
  loggedInUserRequiresConfirmation: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isLoggedIn && rootState.teams.activeTeamId) {
      const teamData = state.loggedInUser.teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return teamData.status === 'pending' && teamData.role !== 'contributor';
      }
    }
    return false;
  },
  loggedInUserIsCreator: (state, getters, rootState, rootGetters) => {
    if (rootGetters.isLoggedIn && rootState.teams.activeTeamId) {
      const teamData = state.loggedInUser.teams.find(
        (team) => team.teamId === rootState.teams.activeTeamId,
      );
      if (teamData) {
        return teamData.role !== 'contributor';
      }
    }
    return false;
  },
  loggedInUserCanStartFlight: (state, getters) => getters.loggedInUserIsCreator
    && !getters.loggedInUserRequiresConfirmation,
  isAdmin: (state, getters) => getters.roleOnActiveTeam === 'team-administrator'
    || getters.roleOnActiveTeam === 'app-administrator',
  hasCreatorPerms: (state, getters) => getters.roleOnActiveTeam !== 'contributor',
  doesEmailExist: (state, getters) => (email) => getters.allProfiles
    .map((profile) => profile.email.toLowerCase())
    .includes(email.toLowerCase()),
};

// actions (async, commit mutations)
const actions = {
  // only called on main route if loggedInUser not set (token exists)
  // or, on pw reset when not logged in
  async initUser({ dispatch, commit }) {
    try {
      const user = await userService.getLoggedInUser();
      commit(SET_USER, user);
      await Promise.all([dispatch('setAllTeams'), dispatch('setAllSegments')]);
      await dispatch('setActiveTeam');
      await dispatch('setProfilesOnTeam');
      const userHash = user.integrations.Intercom
        ? user.integrations.Intercom.user_hash
        : '';
      window.analytics.identify(
        user.id,
        {
          email: user.email,
        },
        {
          Intercom: {
            hideDefaultLauncher: true,
            user_hash: userHash,
          },
        },
      );

      sentry.setUser(user);
    } catch (err) {
      dispatch('logout');
    }
  },
  async setUsersOnTeam({ dispatch, commit, rootGetters }) {
    try {
      const usersOnTeam = await userService.getAllUsersOnTeam(
        rootGetters.activeTeam.id,
      );
      const normalized = normalize(usersOnTeam, userListSchema);
      commit(SET_ALL_USERS, {
        allUsers: normalized.entities.users,
        userList: normalized.result,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem fetching your team data');
    }
  },
  async setProfilesOnTeam({ dispatch, commit, rootGetters }, profiles) {
    try {
      const profilesOnTeam = profiles
        || (await userService.getAllProfilesOnTeam(rootGetters.activeTeam.id));
      const normalized = normalize(profilesOnTeam, profileListSchema);
      commit(SET_ALL_PROFILES, {
        allProfiles: normalized.entities.profiles,
        profileList: normalized.result,
      });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem fetching your team data');
    }
  },
  async saveNotificationPrefs({ dispatch, commit }, prefs) {
    try {
      const updatedUser = await userService.saveNotificationPrefs({
        userId: state.loggedInUser.id,
        notifications: prefs,
      });
      commit(SET_USER, updatedUser);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch(
        'errorToast',
        'There was a problem saving your notification preferences.',
      );
      throw Error('There was a problem saving your notification preferences.');
    }
  },
  async inviteAllUsers({ dispatch, commit, rootState }, users) {
    try {
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        users,
      );
      await dispatch('setProfilesOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error('There was a problem inviting the user(s) to your team');
    }
  },
  async resendInvite({ dispatch, rootState }, email) {
    try {
      const teamId = rootState.teams.activeTeamId;
      await userService.resendInvite({ teamId, email });
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      throw Error(
        'There was a problem resending the invitation. Please refresh the page and try again.',
      );
    }
  },
  async makeUserAdmin({ dispatch, commit, rootState }, data) {
    try {
      const update = data;
      update.teamRole = 'team-administrator';
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        { updates: [update] },
      );
      await dispatch('setUsersOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem updating the user');
    }
  },
  async makeUserCreator({ dispatch, commit, rootState }, data) {
    try {
      const update = data;
      update.teamRole = 'creator';
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        { updates: [update] },
      );
      await dispatch('setUsersOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem updating the user');
    }
  },
  async makeUserContributor({ dispatch, commit, rootState }, data) {
    try {
      const update = data;
      update.teamRole = 'contributor';
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        { updates: [update] },
      );
      await dispatch('setUsersOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem updating the user');
      throw new Error(err);
    }
  },
  async deactivateUser({ dispatch, commit, rootState }, data) {
    try {
      const update = data;
      update.teamStatus = 'deactivated';
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        { updates: [update] },
      );
      await dispatch('setUsersOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem deactivating the user');
    }
  },
  async reactivateUser({
    dispatch, getters, commit, rootState,
  }, data) {
    try {
      const update = data;
      update.teamStatus = getters.previousStatusById(data.userId);
      const { subscription } = await userService.updateTeamMembers(
        rootState.teams.activeTeamId,
        { updates: [update] },
      );
      await dispatch('setUsersOnTeam');
      commit(SET_TEAM_SUBSCRIPTION, subscription);
    } catch (err) {
      dispatch('logErrorToFS', err.message);
      dispatch('errorToast', 'There was a problem re-activating the user');
    }
  },
};

// mutations (modify state)
const mutations = {
  [SET_USER](state, payload) {
    state.loggedInUser = { ...payload };
    state.loggedInUser.fullName = `${payload.firstName} ${payload.lastName}`;
  },
  [SET_ALL_USERS](state, usersData) {
    state.allUsers = usersData.allUsers;
    state.userList = usersData.userList;
  },
  [SET_ALL_PROFILES](state, profilesData) {
    state.allProfiles = profilesData.allProfiles;
    state.profileList = profilesData.profileList;
  },
  [UPDATE_USER](state, userData) {
    state.allUsers[userData.id] = userData;
  },
  [RESET_STORE](state) {
    state.userList = [];
    state.allUsers = {};
    state.profileList = [];
    state.allProfiles = {};
    state.loggedInUser = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
