import upperFirst from 'lodash.upperfirst';
import camelCase from 'lodash.camelcase';

const plugin = {
  install(Vue) {
    const requireComponent = require.context(
      '@/components/Globals',
      false,
      /[A-Z]\w+\.(vue|js)$/,
    );

    requireComponent.keys().forEach((fileName) => {
      const componentConfig = requireComponent(fileName);
      const componentName = upperFirst(
        camelCase(
          fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''),
        ),
      );

      Vue.component(
        componentName,
        componentConfig.default || componentConfig,
      );
    });
  },
};

export default plugin;
