<template>
  <form
    class="balloon-comment-form d-flex justify-content-between align-items-center mt-4"
    :id="`comment-form-${_uid}`"
    @submit.prevent="submitComment">
    <ExpandingTextarea
      v-model="formData.text"
      ref="comment-input"
      @keydown.meta.enter.native="submitComment"
      placeholder="Type your comment"/>

    <div class="d-flex">
      <button
        type="button"
        @click="cancel"
        class="ml-1 tertiary"
        :disabled="pending">
          clear
        </button>

      <PrimaryButton
        type="submit"
        :form="`comment-form-${_uid}`"
        :pending="pending"
        :primary="false"
        :disabled="isDisabled">
          submit
        </PrimaryButton>
    </div>
  </form>
</template>

<script>
export default {
  name: 'BalloonCommentForm',
  props: {
    balloonId: { type: String, required: true },
    commentMode: { type: Boolean, default: false },
  },

  data() {
    return {
      pending: true,
      formData: {
        type: Object,
        text: '',
        balloonId: '',
      },
    };
  },

  computed: {
    isDisabled() {
      return this.pending || this.formData.text.length < 2;
    },
  },

  watch: {
    commentMode() {
      this.focusInput();
    },
  },

  created() {
    this.resetData();
  },

  methods: {
    async submitComment() {
      if (this.disable) {
        return;
      }

      try {
        this.pending = true;
        this.formData.balloonId = this.balloonId;
        await this.$store.dispatch('createComment', this.formData);
        this.$emit('submit');
        this.resetData();
      } catch (err) {
        this.pending = false;
      }
    },

    resetData() {
      this.pending = false;
      this.formData = {
        balloonId: '',
        text: '',
      };
    },

    cancel() {
      this.$emit('cancel');
      this.resetData();
    },

    focusInput() {
      this.$nextTick(() => this.$refs['comment-input'].$refs.textarea.focus());
    },
  },
};
</script>

<style lang="less" scoped>
.balloon-comment-form {
  border-radius: 1px;
  border: 1px solid @navy;
  padding-left: 2rem;
  padding-right: 4rem;

  button[type="submit"]{
    color: @green;
  }
}

::v-deep textarea {
  min-height: 36px;
  height: 36px;
  font-size: 1.3rem;
  font-weight: 500;
  color: @navy;

  &:focus {
    border: none;
  }

  &::placeholder {
    font-size: 1.3rem;
    font-weight: 500;
    color: @navy;
  }
}
</style>
