<template>
  <keep-alive include="FlightsListView">
    <LoadingBalloonAnimation v-if="isLoading" />
    <router-view v-if="!isLoading" />
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlightsLayout',

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters([
      'allFlights',
      'activeTeam',
    ]),
  },

  created() {
    this.fetchFlightsAndTemplates();
  },

  watch: {
    activeTeam() {
      this.fetchFlightsAndTemplates();
    },
  },

  methods: {
    async fetchFlightsAndTemplates() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('setFlights');
        await this.$store.dispatch('setTemplates');
      } catch (e) {
        this.$store.dispatch('errorToast', e.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
