<template>
  <p>
    <span v-for="(str, i) in parsed" :key="i">
      <template v-if="str.isUrl">
        <a class="underline" :href="str.str" target="_blank">
          {{str.str}}
          <img class="anchor-icon" src="/images/icons/open-new-window.svg" alt="new window"/>
        </a>
      </template>
      <template v-else>{{str.str}}</template>
    </span>
  </p>
</template>

<script>
export default {
  name: 'ParseUrls',
  props: {
    text: { type: String, required: true, default: '' },
  },

  computed: {
    parsed() {
      return this.text
        .split(/(\b(?:https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim)
        .map((str, i) => {
          if (i % 2 === 0 && str) return { str, isUrl: false };
          return { str, isUrl: true };
        })
        .filter((str) => str.str);
    },
  },
};
</script>

<style scoped>
a {
  word-wrap: break-word;
  hyphens: auto;
}

.anchor-icon {
  width: 10px;
  display: inline;
  margin: 0 5px;
  opacity: .5;
}
</style>
