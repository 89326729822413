import VueCookies from 'vue-cookies';
import JWTDecode from 'jwt-decode';
import { getCredentialed, getToken } from './localStorage';

export function get() {
  let token = VueCookies.get('token');
  if (!token) {
    token = getToken();
  }
  return token;
}

export function removeCookie() {
  VueCookies.remove('token');
}

function isContributorToken(decoded) {
  return decoded.scopes === 'contributor';
}

export function unpackToken(token) {
  if (token) {
    try {
      return JWTDecode(token);
    } catch (e) {
      return null;
    }
  }
  return null;
}

function getUnpackedToken() {
  const token = get();
  if (token) {
    return unpackToken(token);
  }
  return null;
}

export function getUserIdFromToken(token) {
  try {
    return unpackToken(token).sub;
  } catch (e) {
    throw Error('error getting the stored user id');
  }
}

export function getUserId() {
  const token = get();
  return getUserIdFromToken(token);
}

export function getTeamIdFromToken(token) {
  try {
    return unpackToken(token).aud;
  } catch (e) {
    throw Error('error getting the stored team id');
  }
}

export function getTeamId() {
  const token = get();
  return getTeamIdFromToken(token);
}

export function getFlightId() {
  try {
    return getUnpackedToken().flightId;
  } catch (e) {
    return '';
  }
}

export function allowedForFlight(flightId) {
  const tokenFlightId = getFlightId();
  if (tokenFlightId === '' || tokenFlightId === flightId) {
    return true;
  }
  return false;
}

export function getExpirationDate() {
  const unpacked = getUnpackedToken();
  if (unpacked) {
    if (isContributorToken(unpacked)) {
      const now = new Date();
      return new Date(now.setDate(now.getDate() + 14));
    }
    return new Date(unpacked.exp * 1000);
  }
  return null;
}

export function isCredentialed() {
  const token = VueCookies.get('token');
  if (!token) {
    return getCredentialed();
  }
  return true;
}
