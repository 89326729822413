<template>
  <button :type="type" :class="{ 'is-loading': isLoading }" @click="click">
    <span v-show="!isLoading">
      <slot></slot>
    </span>
    <svg v-show="isLoading" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <circle cx="10" cy="10" r="9" fill="none" stroke-linecap="round" stroke-width="2" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    isLoading: { type: Boolean, default: false },
    type: { type: String, default: 'button' },
    to: { type: Object },
  },

  methods: {
    click() {
      if (this.to) {
        this.$router.push(this.to);
        return;
      }

      this.$emit('click');
    },
  },
};
</script>

<style lang="less" scoped>
button {
  position: relative;

  &.primary {
    circle {
      stroke: @white;
    }

    &.outline {
      background: transparent;
      color: @green;
      border: 1px solid @green;
    }
  }

  &.tertiary {
    &.outline {
      padding: 1rem 3rem;
      background: transparent;
      color: @medium-tan;
      border: 1px solid @medium-tan;
    }
  }

  &.secondary {
    border-bottom: 0;
  }

  &.lg {
    width: 100%;
  }

  &.md {
    width: 45%;
  }

  circle {
    stroke-dasharray: 58;
    stroke-dashoffset: 116;
    animation: load-circle 2s ease-out forwards infinite;
    stroke: @green;
  }

  @keyframes load-circle {
    100%{
      stroke-dashoffset: 0;
    }
  }

  &.is-loading,
  &.is-loading * {
    color: transparent;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
