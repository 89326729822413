<template>
  <router-link :to="to" class="d-flex align-items-center mt-4">
    <icon
      name="back_arrow"
      color="green"
      height="10"
      width="10"
      class="hover-scale d-inline-block mr-3"/>
    Back to flight{{ target === 'templates' ? ' templates' : !flightId ? 's': '' }}
  </router-link>
</template>

<script>
export default {
  name: 'BackToFlightsLink',

  props: {
    flightId: { type: String, default: null },
    target: { type: String, default: 'flights' },
  },

  computed: {
    to() {
      if (this.target === 'templates') {
        return { name: 'flight-templates' };
      }

      return this.flightId
        ? { name: 'flight-landing', params: { id: this.flightId } }
        : { name: 'flights-list' };
    },
  },
};
</script>

<style lang="less" scoped>
a {
  font-size: 1.2rem;
  font-weight: 500;
  color: @green;

  &:visited { color: @green; }
  &:hover { text-decoration: underline; }
}
</style>
