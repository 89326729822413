export const RESET_STORE = 'RESET_STORE';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_SHORTCODE_DATA = 'SET_SHORTCODE_DATA';
export const NEW_TOAST = 'NEW_TOAST';
export const UPDATE_TIME = 'UPDATE_TIME';
export const SET_ACTIVE_TEAM = 'SET_ACTIVE_TEAM';
export const SET_ALL_TEAMS = 'SET_ALL_TEAMS';
export const SET_TEAM_SUBSCRIPTION = 'SET_TEAM_SUBSCRIPTION';
export const ADD_NEW_FLIGHT = 'ADD_NEW_FLIGHT';
export const UPDATE_FLIGHT = 'UPDATE_FLIGHT';
export const REMOVE_FLIGHT = 'REMOVE_FLIGHT';
export const SET_ALL_FLIGHTS = 'SET_ALL_FLIGHTS';
export const NO_FLIGHT_ACCESS = 'NO_FLIGHT_ACCESS';
export const SET_ACTIVE_FLIGHT = 'SET_ACTIVE_FLIGHT';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const ADD_PARTICIPATION = 'ADD_PARTICIPATION';
export const CLEAR_ACTIVE_FLIGHT = 'CLEAR_ACTIVE_FLIGHT';
export const SET_ALL_FLIGHT_PARTICIPATION = 'SET_ALL_FLIGHT_PARTICIPATION';
export const SET_ALL_BALLOONS = 'SET_ALL_BALLOONS';
export const ADD_BALLOON = 'ADD_BALLOON';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_BALLOON = 'UPDATE_BALLOON';
export const DELETE_BALLOON = 'DELETE_BALLOON';
export const SET_BALLOON_RESULTS = 'SET_BALLOON_RESULTS';
export const SET_USER = 'SET_USER';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_ALL_PROFILES = 'SET_ALL_PROFILES';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_ALL_SEGMENTS = 'SET_ALL_SEGMENTS';
export const ADD_SEGMENT = 'ADD_SEGMENT';
export const REMOVE_SEGMENT_FROM_LIST = 'REMOVE_SEGMENT_FROM_LIST';
export const SET_FLIGHT_TEMPLATES = 'SET_FLIGHT_TEMPLATES';
export const SET_ACTIVE_FLIGHT_TEMPLATE = 'SET_ACTIVE_FLIGHT_TEMPLATE';
export const CLEAR_ACTIVE_FLIGHT_TEMPLATE = 'CLEAR_ACTIVE_FLIGHT_TEMPLATE';
export const SET_FLIGHT_TEMPLATE_AUTHORS = 'SET_FLIGHT_TEMPLATE_AUTHORS';
export const SET_FLIGHT_TEMPLATE_SERIES = 'SET_FLIGHT_TEMPLATE_SERIES';
export const SHOW_LEAVE_GUARD = 'SHOW_LEAVE_GUARD';
export const CLEAR_LEAVE_GUARD = 'CLEAR_LEAVE_GUARD';
export const NEW_BANNER = 'NEW_BANNER';
export const CLOSE_BANNER = 'CLOSE_BANNER';
export const CLEAR_BANNERS = 'CLEAR_BANNERS';
export const ANALYTICS_READY = 'ANALYTICS_READY';
