<template>
  <section class="row">
    <div class="col">
      <h5 class="mb-4">Timing</h5>

      <ul v-if="isPostFlight">
        <li>
          <h6>FLIGHT&nbsp;START</h6>
          <small>
            Started {{formatISOString(flight.stages.one.startDate)}}
          </small>
        </li>
        <li>
          <h6>FLIGHT&nbsp;END</h6>
          <small>
            Ended {{formatISOString(flight.stages.two.endDate)}}
          </small>
        </li>
      </ul>

      <ul v-else>
        <li :class="{active: isStageOne}">
          <h6>
            <small>Stage 1</small> - Contribute
            <span
              class="alert"
              data-tour="stage-one-time-left"
            >
              {{ timeLeftInStageString(flight.id) }}
            </span>
          </h6>
          <small>
            <time>
              Start{{isStageOne ? 'ed' : 's'}} {{formatISOString(flight.stages.one.startDate)}}
            </time>
          </small>
          <div class="hover-tip-wrapper" tabindex="0">
            <div class="hover-tip-area"></div>
            <icon name="info-circle" width="12" height="12"/>
            <HoverTip class="p-4 hover-tip" position="top">
              <p class="font-weight-bold mb-2">Stage 1</p>
              <p class="mb-2">
                In Stage 1, you can contribute your ideas, feedback, or information.
                Submit as many "balloons" (responses, to the question above) as you want here.
                Try to only submit one idea per balloon!
              </p>
              <p>
                When you are ready, you can toggle on collaborate mode to reveal
                and start commenting on other contributors' balloons.
                <a class="underline" target="_blank" href="https://help.balloon.app/en/articles/2543449-stage-1-launching-and-discussing-balloons">Learn More</a>
              </p>
            </HoverTip>
          </div>
        </li>
        <li class="stage-list-item" :class="{active: isStageTwo}">
          <h6>
            <small>Stage 2</small> - Pump
            <span
              class="alert"
              data-tour="stage-two-time-left"
            >
              {{ timeLeftInStageString(flight.id) }}
            </span>
          </h6>
          <small>
            <time>
              Start{{isStageTwo ? 'ed' : 's'}} {{formatISOString(flight.stages.one.endDate)}}
            </time>
          </small>
          <div class="hover-tip-wrapper" tabindex="0">
            <div class="hover-tip-area"></div>
            <icon name="info-circle" width="12" height="12"/>
            <HoverTip class="p-4 hover-tip" position="top">
              <p class="font-weight-bold mb-2">Stage 2</p>
              <p class="mb-2">
                In Stage 2, you vote on the responses by pumping
                the balloons that you like or support.
              </p>
              <p>
                Click once for balloons you support/like and twice for your top choices.
                <a class="underline" target="_blank" href="https://help.balloon.app/en/articles/2543455-stage-2-pumping-balloons">Learn More</a>
              </p>
            </HoverTip>
          </div>
        </li>
        <li class="stage-list-item">
          <h6>
            <small>Flight End</small> - Results
            <span class="alert">{{timeLeftInStageString(flight.id)}}</span>
          </h6>
          <small>
            <time>
              Ends {{formatISOString(flight.stages.two.endDate)}}
            </time>
          </small>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import flightDetail from '@/mixins/flight-detail';

export default {
  name: 'FlightSidebarStage',

  mixins: [flightDetail],

  props: {
    flight: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      'timeLeftInStageString',
      'formatISOString',
    ]),
  },
};
</script>

<style lang="less" scoped>
ul {
  li {
    background: @light-tan;
    position: relative;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    margin-bottom: .8rem;

    &.active {
      background: @tan;

      h6 {
        span.alert {
          display: inline-block;
        }
      }
    }

    h6 {
      span.alert {
        display: none;
        position: absolute;
        right: 20px;
      }

      small {
        font-size: 1rem;
      }
    }

    small {
      font-size: .9rem;
      text-transform: none;
      letter-spacing: normal;
    }
  }
}

.hover-tip-wrapper {
  right: 0;
  bottom: 0;

  svg {
    margin-right: 1.2rem;
    margin-bottom: 1rem;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .hover-tip-area {
    width: 100%;
    height: 20px;
    bottom: 0;
    right: 0;
    position: absolute;
  }

  .hover-tip {
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 25rem;
    transition: opacity .25s linear, visibility .25s;
    p { color: @tan; }
    ::v-deep .top {
      left: auto;
      right: 15px;
    }
  }

  svg { cursor: pointer; }

  &:hover .hover-tip,
  &:focus .hover-tip{
    opacity: 1 !important;
    visibility: visible;
    pointer-events: auto;
    z-index: 1;
  }
}

@media (hover: hover) {
  .hover-tip-wrapper:not(:hover) {
    .hover-tip {
      visibility: hidden;
      opacity: 0;
    }
    .hover-tip-area {
      visibility: hidden;
    }
  }
}

</style>
