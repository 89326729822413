import Vue from 'vue';
import stripe from '@/services/stripe';

export default {
  async getSubscriptionPlans() {
    try {
      const response = await Vue.axios.get('api/subscriptions/plans');

      return response.data;
    } catch (err) {
      throw Error(`API error at subscription.getSubscriptionPlans: ${err.message}`);
    }
  },
  async getTeamSubscription(teamId) {
    try {
      const response = await Vue.axios.get(`api/v1/teams/${teamId}/subscription`);

      return response.data;
    } catch (err) {
      throw Error(`API error at subscriptionService.getTeamSubscription: ${err.message}`);
    }
  },
  async previewSubscriptionUpdate(teamId, plans) {
    try {
      const response = await Vue.axios.post(`api/v1/teams/${teamId}/subscription/preview`, plans);
      return response.data;
    } catch (err) {
      throw Error(`API error at subscriptionService.previewSubscriptionUpdate: ${err.message}`);
    }
  },
  async updateTeamPaymentMethod(teamId, card) {
    try {
      const { source } = await stripe.createSource(card);

      const response = await Vue.axios.patch(`api/v1/teams/${teamId}/payment`, {
        paymentId: source.id,
      });

      return response.data;
    } catch (err) {
      throw Error(`API error at subscriptionService.updateTeamPaymentMethod: ${err.message}`);
    }
  },

  async cancelTeamSubscription(teamId) {
    try {
      const response = await Vue.axios.delete(`api/v1/teams/${teamId}/subscription`);
      return response;
    } catch (err) {
      throw Error(`API error at subscriptionService.cancelTeamSubscription: ${err.message}`);
    }
  },

  async restartTeamSubscription(teamId) {
    try {
      const response = await Vue.axios.patch(`api/v1/teams/${teamId}/subscription/restart`);
      return response;
    } catch (err) {
      throw Error(`API error at subscriptionService.restartTeamSubscription: ${err.message}`);
    }
  },
};
